.epp_card {
    display: flex;
    background-color: #5944d1;
    border-radius: 10px;
    padding: 2rem;
    flex-direction: column;
    position: relative;
    z-index: 100;
}

.epp_card_shadow{
    background-color: red;
    // background-color: #DDE1FE;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    bottom: -20px;
    left: -20px;
    border-radius: 10px;
    z-index:-10000000;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.epp_card_heading_div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.epp_card_exp_img {
    height: 50px;
    width: 50px;
    object-fit: contain;
}

.epp_card_exp_img1 {
    height: 200px;
    width: 200px;
    object-fit: contain;
    position: absolute;
    top: -50px;
    right: -50px;
}

.epp_card_heading {
    font-size: 26px;
    font-family: Roboto;
    font-weight: 600;
    color: #fff;
}

.epp_card_text {
    font-size: 18px;
    font-family: Roboto;
    font-weight: 400;
    color: #fff;
    letter-spacing: 0.5px;
    margin-top: 1rem;
    line-height: 27px;
    margin-right: 100px;
}

.epp_card_play {
    height: 43px;
    width: 43px;
    object-fit: contain;
}

.epp_card_play_heading {
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    color: #fff;
    letter-spacing: 0.5px;
    line-height: 20px;
    text-align: center;
}

.epp_card_play_div {
    display: flex;
    align-self: flex-end;
    gap: 10px;
    margin-top: 20px;
    cursor: pointer;
}