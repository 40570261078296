.heading {
  width: 100%;
  color: #4148c8;
  font-size: 30px;
  font-family: Roboto Condensed;
  font-weight: 500;
  text-transform: capitalize;
  word-wrap: break-word;
}
.card {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 10px;
}
.subCard {
  background: #f0f2ff;
  border-radius: 5px;
  border: 1px #bcbde7 solid;
  padding: 20px 50px;
  margin-bottom: 26px;
}
.accName {
  color: #4e54be;
  font-size: 27px;
  font-family: Roboto;
  font-weight: 700;
  text-transform: capitalize;
  word-wrap: break-word;
}
.accHolder {
  color: #575959;
  font-size: 17px;
  font-family: Roboto;
  font-weight: 500;
  text-transform: capitalize;
  word-wrap: break-word;
}
.editBtn {
  background: #5258d9;
  border-radius: 30px;
  padding: 5px 30px;
  border: none;
  float: right;
  color: #ffffff;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.editIcon {
  border: none;
  height: 14px;
  margin-right: 5px;
}
.inBox {
  height: 40px;
  border-radius: 5px;
  border: 1px solid #b3b5e8;
  background: #fff;
  width: 47%;
  margin: 0 22px 0 0;
  color: #343434;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.inDiv {
  margin-top: 20px;
}
.subName {
  color: #4a4a4a;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 25px 0;
}
.addBtn {
  background: #5258d9;
  border-radius: 30px;
  padding: 5px 10px;
  border: none;
  float: right;
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: center;
  cursor: pointer;
}
.addIcon {
  border: none;
  height: 17.6px;
  margin-right: 5px;
}
.addSpan {
  margin-top: 2px;
}
.selBtn {
  border-radius: 50px;
  border: 2px solid #b3b5e8;
  background: #fff;
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 14px 30px;
  margin-bottom: 17px;
}
.plusIcon {
  height: 10.267px;
  margin-right: 10px;
}
.abouttxt {
  color: #4a4a4a;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}
.subSection {
  border-bottom: 1px solid #5258d966;
}
.subSecTxt {
  color: #5258d9;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 25px 0;
}
.hwDiv {
  display: flex;
}
.hDiv {
  width: 47%;
  color: #656161;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.hInBox {
  height: 40px;
  border-radius: 5px;
  border: 1px solid #b3b5e8;
  background: #fff;
  width: 72px;
  color: #343434;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}
.unit {
  padding: 14.5px 13px;
  border-radius: 0px 4px 4px 0px;
  background: #5258d9;
  color: #fff;
  margin-left: -3px;
  margin-right: 22px;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}
.sInBox {
  height: 40px;
  border-radius: 50px 0 0 50px;
  border: 1px solid #b3b5e8;
  background: #fff;
  width: 46px;
  color: #343434;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}
.sunit {
  padding: 13px 21px;
  border-radius: 0px 50px 50px 0px;
  background: #5258d9;
  color: #fff;
  margin-left: -3px;
  margin-right: 22px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.agDiv {
  display: flex;
  flex-wrap: wrap;
}
.aInBox {
  height: 40px;
  border-radius: 5px;
  border: 1px solid #b3b5e8;
  background: #fff;
  width: 114px;
  color: #343434;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0 20px 20px 0;
  text-align: center;
}
.aInBox.hovered,
.aInBox:hover {
  height: 40px;
  border-radius: 5px;
  border: 1px solid #b3b5e8;
  background: #5258d9;
  width: 114px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0 20px 20px 0;
  text-align: center;
}
.agText {
  color: #4a4a4a;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-top: 32px;
}
.agTextDes {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}
.prefCard {
  background: white;
  border-radius: 5px;
  border: 1px #b3b5e8 solid;
  padding: 48px;
}
.headDiv {
  border-bottom: 1px solid #5258d9;
  padding-bottom: 25px;
}
.prefHeadSel {
  color: #5258d9;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 600;
  line-height: 12px;
  word-wrap: break-word;
  margin-right: 47px;
  border-bottom: 4px solid #5258d9;
  padding-bottom: 20px;
  margin-left: 10px;
}
.prefHead {
  color: #5258d9;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 600;
  line-height: 12px;
  word-wrap: break-word;
  margin-right: 47px;
}
.listDiv {
  border-bottom: 1px solid #b3b5e8;
  padding-left: 20px;
}
.addBtn1 {
  background: #5258d9;
  border-radius: 30px;
  padding: 5px 10px;
  border: none;
  float: right;
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: center;
  cursor: pointer;
  width: 185px;
  height: 34px;
}
.addIcon1 {
  border: none;
  height: 18px;
  margin-right: 5px;
  margin-top: 3px;
}
.addSpan1 {
  margin-top: 4px;
}
