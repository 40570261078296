.home {
  width: 100vw;
  overflow-y: hidden !important;
  position: relative;
  /* display: flex; */
}
.home-main {
  position: relative;
  width: 100%;
}
.home-img {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100vw;
  min-height: 1109px;
  flex-shrink: 0;
  z-index: -1;
  right: 0;
}
.home-img-layer {
  position: absolute;
  top: 670px;
  right: 0;
  transform: rotate(5deg);
}
.pro-cat {
  width: 100vw;
  margin-top: 3rem;
  background: linear-gradient(
    0deg,
    #252bbe -32.6%,
    #4047c7 52.17%,
    #813dec 125.41%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}
.pro-review {
  width: 100vw;
  margin-top: 3rem;
  background: linear-gradient(
    90deg,
    #252bbe -6.73%,
    #4047c7 40.92%,
    #813dec 82.09%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_girl {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.home_video {
  height: 100%;
  width: 100%;
  object-fit: contain;
  /* cursor: pointer; */
  /* border-radius: 50%; */
  overflow: visible;
  z-index: 0;
  /* background-color: #252bbe; */
}
.home_video_text_video_div {
  /* background-color: #fff; */
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  width: 320px;
  height: 320px;
}
.home_video_text {
  width: 150px;
  height: 60px;
  position: absolute;
  display: flex;
  top: 0rem;
  left: -13px;
  object-fit: cover;
  cursor: pointer;
  transform: rotate(-36deg);
}
.home_video_div {
  height: 570px;
  width: 570px;
  display: flex;
  position: relative;
  flex-direction: column;
}
.home_video_div_main {
  height: 660px;
  width: 620px;
  display: flex;
  position: relative;
  align-items: flex-end;
  /* background-color: red; */
}
.home_top_header {
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-top: 3rem;
}
.home_top_header_left {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: -20px;
  position: relative;
}
.home_top_header_right {
  display: flex;
  flex: 1;
  position: relative;
  justify-content: center;
  padding-right: 2rem;
  flex-direction: column;
}
.home_top_header_right_text {
  color: #fff;
  font-family: Roboto;
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 66px;
}
.home_top_header_right_text1 {
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  margin-block: 2rem;
}
.home_top_header_right_text_bold {
  font-weight: 900;
}
.home_top_header_btn_div {
  display: flex;
}
.home_top_header_btn {
}

.home_top_header_btn1 {
  background: #3d40b5;
  color: #ffffff;
  width: 265px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 2px solid #fff;

  cursor: pointer;
  transition: 0.5s;
  font-weight: 400;
  font-size: 16px;
}
.home_top_header_btn1:hover {
  transition: 0.5s;
  color: #3d40b5;
  background-color: #ffffff;
}
.home_top_header_btn2 {
  background: #ffffff;
  color: #3d40b5;
  width: 20rem;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 2px solid #fff;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 400;
  font-size: 16px;
}
.home_top_header_btn2:hover {
  transition: 0.5s;
  background: #3d40b5;
  color: #ffffff;
}
.home_top_header_btn2 > svg > path {
  transition: 0.5s;
}
.home_top_header_btn2:hover > svg > path {
  transition: 0.5s;
  /* background-color: rgb(255, 255, 255); */
  stroke: #ffffff;
}
.home_top_header_btn21 {
  background: #ffffff;
  color: #3d40b5;
  /* width: 265px; */
  /* height: 50px; */

  border-radius: 50px;
  border: 2px solid #fff;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 800;
  font-size: 16px;
  /* display: flex; */
}
.home_top_header_btn21:hover {
  transition: 0.5s;
  background: #3d40b5;
  color: #ffffff;
}
.home_top_header_bill_btn_div {
  display: flex;
  padding-left: 6rem;
  margin-top: 3rem;
}
.home_playme_img {
  position: absolute;
  right: 45px;
  top: 0px;
  width: 69px;
  object-fit: fill;
  flex-shrink: 0;
  color: white;
  word-wrap: break-word;
  cursor: pointer;
  transform: rotate(92deg);
}
.home_playme_img > span {
  font-family: Roboto;
  position: absolute;
  /* transform: rotate(275deg); */
  font-size: 20px;
  font-weight: 100;
}
.home_playme_img1 {
  top: 120px;
  right: -10px;
  transform: rotate(250deg);
}
.home_playme_img2 {
  top: 77px;
  right: 12px;
  transform: rotate(265deg);
}
.home_playme_img3 {
  top: 43px;
  right: 10px;
  transform: rotate(275deg);
}
.home_playme_img4 {
  top: 10px;
  right: 5px;
  transform: rotate(285deg);
}
.home_playme_img5 {
  top: -20px;
  right: -10px;
  transform: rotate(305deg);
}
@media screen and (max-width: 1200px) {
  /* .home-img {
    top: -550px;
    left: 0;
    width: 100vw !important;
    object-fit: cover;
    height: 400.188px !important;
  } */

  .home_video_div {
    height: 390px;
    width: 390px;
    display: flex;
    position: relative;
    /* background-color: red; */
  }
  .home_video_text_video_div {
    /* background-color: yellow; */
    width: 180px;
    height: 180px;
  }
  .home_video_text {
    width: 100px;
    height: 30px;
    position: absolute;
    display: flex;
    top: -1.2rem;
    object-fit: contain;
    cursor: pointer;
  }
  .home_video_div_main {
    height: 405px;
    width: 390px;
    /* background-color: antiquewhite; */
  }
  .home_top_header_bill_btn_div {
    display: flex;
    padding-left: 2rem;
    /* margin-top: 1rem; */
    /* background-color: red; */
  }
}
@media screen and (max-width: 850px) {
}

@media screen and (max-width: 500px) {
  .home_top_header_bill_btn_div {
    display: flex;
    padding-left: 2rem;
  }
  .home-img {
    width: 100%;
    min-height: 230px;
    /* height: 100%; */
    /* object-fit: fill; */
    display: flex;
  }
  .home_top_header_btn2 {
    background: #ffffff;
    color: #3d40b5;
    width: 100px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 50px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: 0.5s;
    font-weight: 400;
    font-size: 10px;
  }
  .home_top_header_btn1 {
    background: #3d40b5;
    color: #ffffff;
    width: 100px;
    height: 20px;
    /* flex-shrink: 0; */
    border-radius: 50px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: 0.5s;
    font-weight: 400;
    font-size: 10px;
  }
  .home_top_header_btn_div {
    display: flex;
    flex-direction: column;
  }
  .home_top_header {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
  }
  .home_top_header_left {
    /* background-color: red; */
    flex: 1;
  }
  .home_top_header_right {
    flex: 1;
  }

  .home_top_header_right_text {
    color: #fff;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
  }
  .home_top_header_right_text1 {
    color: #fff;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    margin-block: 0.5rem;
  }
  .home_top_header_right_text_bold {
    font-weight: 500;
  }
  .home_video_div {
    height: 135px;
    width: 135px;
    display: flex;
    position: relative;
  }
  .home_video_text {
    width: 30px;
    height: 10px;
    position: absolute;
    display: flex;
    top: -0.4rem;
    object-fit: contain;
    cursor: pointer;
  }
  .home_video_div_main {
    height: 145px;
    width: 120px;
    /* background-color: antiquewhite; */
  }
  .home_video_text_video_div {
    /* background-color: yellow; */
    width: 60px;
    height: 60px;
  }
  /* .home-img {
    position: absolute;
    top: -80px;
    left: 0;
    width: 100vw;
    height: auto;
    max-height: 100vh !important;
    object-fit: cover;
    flex-shrink: 0;
    z-index: -1;
  } */
  .home-img-layer {
    display: none;
    position: absolute;
    height: 150px;
    width: 200px;
    top: 670px;
    right: 0;
    transform: rotate(5deg);
  }
  .homecard_1 {
    /* background-color: red; */
    /* padding: 1rem 0; */
    /* width: 100%; */
    /* display: flex; */
    /* width: 100vw;
    align-self: center; */
  }
}
@media screen and (min-width: 1500px) {
  .homecard {
    margin-top: -8rem;
  }
  .home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden !important;
    width: 100%;
    /* background-color: red; */
  }

  .home_data_2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* overflow-y: hidden !important; */
    max-width: 1500px;
    width: 100%;
  }
  .home-main {
    position: relative;
    width: 100vw;
    /* background-color: yellow; */
    display: flex;
    max-height: 50rem;
    height: 100%;
  }
  .home-img {
    width: 100%;
    max-height: 40rem;
    height: 100%;
    object-fit: fill;
    display: flex;
  }
  .home-img-layer {
    display: none;
  }
  .pro-cat {
    width: 100%;
    background-color: #4047c7;
    display: flex;
  }
}
