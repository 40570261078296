.category_item_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  width: 120px;
}
.category_item_img {
  height: 70%;
  width: 70%;
  object-fit: contain;
}
.category_item_img:hover {
  height: 75%;
  width: 75%;
}

.category_item_img_div {
  height: 120px;
  width: 120px;
  display: flex;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #5358d9;
  overflow: hidden;
}
.category_item_title {
  font-size: 15px;
  font-family: Roboto;
  font-weight: 500;
  color: #4146bf;
  text-align: center;
}
/* @media screen and (max-width: 1000px) {
  .category_item_title {
  font-size: 15px;
  font-family: Roboto;
  font-weight: 500;
  color: #4146bf;
  text-align: center;
}
} */
