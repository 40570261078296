/* @font-face {
  font-family: "Archia";
  src: 
    url("./utils/Fonts/Archia/Archia-Regular.otf") format("opentype"),
    url("./utils/Fonts/Archia/Archia-Medium.otf") format("opentype"),
    url("./utils/Fonts/Archia/Archia-SemiBold.otf") format("opentype"),
    url("./utils/Fonts/Archia/Archia-Bold.otf") format("opentype");
} */
/* @media screen and (min-width:1500px) {
  .App{
    display: flex;
    max-width: 100%;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
  }
} */
.App {
  height: 100vh;
  width: 100vw;
  position: relative;
}
* {
  padding: 0;
  margin: 0;
  font-family: "Roboto";
}
p {
  padding: 0;
  margin: 0;
}
.position1 {
  position: relative;
}
.dfc {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Animation Left */

.animatedLeft {
  animation-duration: 3s;
  animation-fill-mode: both;
  -webkit-animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}

.animatedFadeInLeft {
  opacity: 0;
}

.fadeInLeft {
  opacity: 0;
  animation-name: fadeInLeft;
  -webkit-animation-name: fadeInLeft;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Animation Right */

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animatedRight {
  animation-duration: 3s;
  animation-fill-mode: both;
  -webkit-animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}

.animatedFadeInRight {
  opacity: 0;
}

.fadeInRight {
  opacity: 0;
  animation-name: fadeInRight;
  -webkit-animation-name: fadeInRight;
}

/* Animation for Login Popup */

.scroll-in-animation {
  width: 100%;
  animation: npScrollIn 2s ease-out;
  animation-iteration-count: 1;
  overflow: hidden;
}

@keyframes npScrollIn {
  0% {
    max-height: 0px;
  }

  100% {
    max-height: 500px;
  }
}

/* Animation for Login Popup Out*/

.scroll-out-animation {
  width: 100%;
  animation: npScrollOut 1s ease;
  animation-iteration-count: 1;
  overflow: hidden;
}

@keyframes npScrollOut {
  0% {
    max-height: 500px;
  }

  100% {
    max-height: 0;
  }
}

/* Dropdowm CSS */

.DWM_2_sort {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}
.DWM_2_sort > div > div > div > p {
  color: #252abe;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.DWM_2_sort > div > div > p {
  color: #252abe;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.DWM_2_sort_1 {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.app-main {
  margin-top: 89px;
  width: 100vw;
}

@media screen and (max-width: 500px) {
  .app-main {
    margin-top: 45px;
  }
  .ant-modal-content {
    width: calc(100vw - 2rem) !important;
    margin: 0 1rem !important;
  }
}
:root {
  --color-button: #5258d9;
  --color-button-hover: #6d72e9;
  --color-notification: #b8f8e9;
  --color-primary: #252abe;
  --color-text: #030002;
  --color-QTY: #dde1fe;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}
