.Data_dash {
  display: flex;
  flex-direction: column;
}
.Data_1 {
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  width: calc(100% - 6%);
  padding: 2rem 3%;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
.Data_2 {
  display: flex;
  width: calc(100% - 8%);
  padding: 1rem 4% 0 4%;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  gap: 1.85rem;
  div {
    color: #252bbe;
    text-align: center;
    font-family: "Roboto Condensed";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 66.667% */
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
  }
}
.Data_3 {
  background-color: #f0f2ff;
  padding: 2rem 4rem;
  width: calc(100% - 8rem);
}
.Data_title {
  color: #252bbe;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 100% */
  text-transform: uppercase;
}
.Data_title_img {
  width: 31px;
  height: 28.568px;
  flex-shrink: 0;
  object-fit: contain;
}

// Data Demandey

.Data_demandey {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.data_de_1 {
  display: flex;
  gap: 2rem;
  justify-content: center;
}
.data_de_1_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;
  background: linear-gradient(138deg, #4047c7 48.47%, #813dec 95.25%);
  border: 1px solid #252bbe;
  border-radius: 5px;
  width: 70%;
  flex: 7;
  padding: 1rem 1rem;
  div {
    color: #fff;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1.25rem;
    div {
      width: 160px;
      color: #fff;
      text-align: center;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 183.333% */
      text-decoration-line: underline;
    }
    img {
      height: 55px;
      width: 55px;
    }
  }
}
.data_de_1_2 {
  flex: 5;
  padding: 1rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  border: 2px solid #252bbe;
  border-radius: 10px;
  div {
    color: #252abe;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
  }
}
.data_de_1_2_btn {
  width: 105px;
  height: 45px;
  flex-shrink: 0;
  background-color: #252bbe !important;
  color: white !important;
  border-radius: 50px;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.data_de_2 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.data_de_2_title {
  color: #252abe;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 80% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.data_de_cards {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  border-bottom: 1px solid #a3a7ec;
}
.data_de_card {
  display: flex;
  width: calc(275px - 2rem);
  flex-shrink: 0;
  padding: 1rem 1rem 2rem 1rem;
  border-radius: 10px;
  border: 1px solid #c0c4ff;
  background: #fff;
  cursor: pointer;
  gap: 2rem;
}
.data_de_card_right {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.data_de_card_right_title {
  color: #434343;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 75% */
  text-transform: capitalize;
}
.data_de_card_right_content {
  color: #000;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
  text-transform: capitalize;
}
.img_div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  img {
    min-width: 40px;
    max-height: 40px;
    object-fit: cover;
  }
}

// Data With Manufacturers CSS

.DataWithManu {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.DWM_1 {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
}

.DWM_1_1 {
  color: #252bbe;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.5px; /* 107.5% */
  text-transform: capitalize;
}
.DWM_1_2 {
  color: #252bbe;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.5px; /* 136.111% */
}
.DWM_2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.DWM_2_title {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 60% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.DWM_2_sort {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  div {
    div {
      div {
        p {
          color: #252abe;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      p {
        color: #252abe;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
.DWM_2_sort_1 {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.data_de_2_manu_title {
  color: #000;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.manu_card {
  display: flex;
  width: calc(334px);
  flex-shrink: 0;
  padding: 0 0.5rem 0.5rem 0;
  border-radius: 18px;
  border: 1px solid #c0c4ff;
  background: #fff;
  gap: 1rem;
}
.manu_img_div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.data_manu_card_right {
  display: flex;
  flex-direction: column;
  gap: 0.65rem;
  padding: 0.5rem 1rem 0 0;
}
.data_manu_card_right_title {
  color: #020a26;
  font-family: Roboto Condensed;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}
.data_manu_card_right_content {
  color: #020a26;
  font-family: Roboto Condensed;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.5px;
}
.data_manu_card_right_btn {
  width: 225px;
  height: 45px;
  flex-shrink: 0;
  background: linear-gradient(138deg, #4047c7 48.47%, #813dec 95.25%);
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 106.667% */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  .Data_2 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  div {
    text-align: left !important;
  }
  .data_de_1 {
    flex-direction: column;
  }
  .Data_3 {
    background-color: #f0f2ff;
    padding: 2rem 1rem;
    width: calc(100% - 2rem);
  }
  .data_de_1_1 {
    width: calc(100% - 2rem);
    flex-direction: column;
  }
  .data_de_1_1 {
    justify-content: flex-start;
    gap: 1rem;
    div {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}
@media screen and (max-width: 1000px) {
  .Data_2 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  div {
    text-align: left;
  }
  .data_de_1 {
    flex-direction: column;
  }
  .Data_3 {
    background-color: #f0f2ff;
    padding: 2rem 1rem;
    width: calc(100% - 2rem);
  }
  .data_de_1_1 {
    width: calc(100% - 2rem);
    flex-direction: column;
  }
  .data_de_1_1 {
    justify-content: flex-start;
    gap: 1rem;
    div {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}

// ProductDataDashboard CSS
.PDD {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.PDD_main {
  display: flex;
  gap: 1rem;
}
.PDD_main_1st {
  max-width: 467px;
  height: 253px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #252abe;
}
.PDD_main_1st {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}
.PDD_main_1st_left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}
.PDD_main_1st_left_img1 {
  width: 186.069px;
  height: 180.635px;
  flex-shrink: 0;
  border-radius: 9px 0px 0px 0px;
  background-color: #8080802e;
  // background: url(<path-to-image>), lightgray 50% / cover no-repeat;
}
.PDD_main_1st_left_img2 {
  margin-left: 2rem;
  width: 76px;
  height: 33.467px;
  flex-shrink: 0;
}
.PDD_main_1st_right {
  gap: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.PDD_right_1 {
  color: #343434;
  font-family: Roboto Condensed;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.5px; /* 160.714% */
}
.PDD_right_2 {
  color: #343434;
  font-family: Roboto Condensed;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px; /* 138.889% */
}
.PDD_right_3 {
  color: #343434;
  font-family: Roboto Condensed;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 125% */
}
.PDD_right_4 {
  color: #343434;
  font-family: Roboto Condensed;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
}
.PDD_right_5 {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.PDD_right_5_1 {
  color: #343434;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}
.PDD_right_5_2 {
  color: #343434;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
}
.PDD_main_2nd {
  position: relative;
  display: flex;
  width: calc(577px - 3rem);
  height: calc(256px - 3rem);
  flex-shrink: 0;
  border-radius: 5px;
  padding: 1.5rem;
  background: #f0f2ff;
}
.PDD_the_data_div {
  width: calc(243px - 2rem);
  height: 65px;
  flex-shrink: 0;
  padding: 0 1rem;
  background-color: #813dec;
  border-radius: 10px;
  position: absolute;
  right: -62px;
  top: -33px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.agree_img_content_2 {
  width: 115.566px;
  // height: 33.523px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.PDD_main_2nd_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.PDD_main_2nd_main_title {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 85.714% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.PDD_main_2nd_main_content_main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.PDD_main_2nd_main_content_main_1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.PDD_main_2nd_main_content_main_1_1 {
  flex: 1;
  color: #252bbe;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 180% */
}
.PDD_main_2nd_main_content_main_1_2 {
  flex: 2;
  color: #252bbe;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 192.857% */
}
.PDD_btn_view_div {
  width: fit-content;
  display: flex;
  gap: 0.5rem;
  border-radius: 30px;
  background: #5258d9;
  padding: 0.35rem 1.6rem;
  color: white;
  cursor: pointer;
}
.PDD_bottom {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
  width: calc(100% - 10%);
  padding: 1rem 5%;
}
.PDD_main_3rd_main {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.PDD_maint_3rd_box {
  border-radius: 10px;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-content: center;
  width: 270px;
  height: 68.276px;
  flex-shrink: 0;
  background: linear-gradient(138deg, #4047c7 48.47%, #813dec 95.25%);
  stroke-width: 1px;
  stroke: #252bbe;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.5px; /* 154.167% */
  letter-spacing: 1px;
  text-transform: uppercase;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.PDD_maint_3rd_line {
  height: 1px;
  background: #6b6ff3;
  width: 100%;
}
.PDD_main_3rd_content_title {
  color: #252bbe;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
}
.PDD_main_3rd_content_title2 {
  color: #252bbe;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

// Data Expert Answers Component
.DEA_main {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.DEA_title {
  color: #4148c8;
  font-family: Roboto Condensed;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 40% */
  text-transform: capitalize;
}
.DEA_que_div {
  border-radius: 10px;
  background: #fff;
  width: calc(100% - 10%);
  padding: 2rem 5%;
}
.DEA_que_1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 0;
  border-bottom: 1px solid #bcbde7;
}
.DEA_Que1 {
  color: #1c24b4;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.DEA_Ans1 {
  color: #1c24b4;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 1.5rem;
}

@media screen and (max-width: 500px) {
  .data_de_card {
    width: calc(100% - 2rem);
  }
  .DWM_2_title {
    font-size: 15px;
  }
}

// Path To Purchase Component

.PTP_1st {
  display: flex;
  gap: 1rem;
  padding: 1rem 0;
}
.PTP_1st_1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}
.PTP_1st_1_title {
  color: #4148c8;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 112.5% */
  text-transform: capitalize;
}
.PTP_1st_1_desc {
  color: #4646a9;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 138.889% */
}
.PTP_1st_1_sum {
  color: #424242;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 138.462% */
  text-transform: capitalize;
}
.PTP_1st_1_sum_content {
  color: #525252;
  text-align: justify;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 158.333% */
}
.PTP_1st_2 {
  flex: 1;
}
.PTP_2nd {
  flex: 1;
}
.DEA_graph {
  width: 583px;
  height: 397px;
}

.PTP_2nd_1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  span {
    color: #4646a9;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px; /* 166.667% */
  }
}
// .PTP_2nd_2_table { width: 100%;
//   tr {
//     border-bottom: 1px solid #a3a7ec;
//     width: 100%;
//     flex: 1;
//     td {
//       padding: 0.5rem 0;
//       flex: 1;
//       display: flex;
//       width: 100%;
//       background-color: #000;
//     }
//   }

// }
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  // background-color: red;
  // border: 1px solid #ddd;
}
th,
td {
  text-align: left;
  // padding: 8px;
  text-align: center;
}


tr {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #a3a7ec;
  // height: 50px;
  // height: 25px;
}

.PTP_2nd_2_td_1 {
  color: #000;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 315.385% */
  text-align: left;
  width: 160px;
}
.PTP_2nd_2_td_2 {
  color: #5054b2;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 273.333% */
  text-align: left;
  width: 100%;
  
  flex: 1;
}
.PTP_2nd_2_td_3 {
  color: #757575;
  text-align: left;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 273.333% */
}
.ASP_title {
  height: 25px;
  color: #5258d9;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px; /* 83.333% */
}
.LD_edit {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  width: 110px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #5258d9;
  span {
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 106.667% */
  }
  img {
  }
}

// Payment Information CSS

.PI_cards {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem;
}
.PI_card {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: calc(462px - 1rem);
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #bcbde7;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
.PI_card_icon {
  width: 173px;
  height: 115px;
  flex-shrink: 0;
  object-fit: fill;
}
.PI_card_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.PI_card_bank_name {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  text-transform: capitalize;
}
.PI_card_no {
  color: #000;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 70.588% */
  text-transform: capitalize;
}
.PI_card_expiry_actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.PI_card_ex {
  color: #000;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 70.588% */
  text-transform: capitalize;
}
.PI_card_actions {
  color: #4148c8;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 85.714% */
  text-transform: capitalize;
  span {
    cursor: pointer;
  }
}
.PI_add_more_card {
  color: #4148c8;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 80% */
  text-transform: capitalize;
  cursor: pointer;
}

// Shipping Delivery Address

.SDP_content_1 {
  width: 226px;
  height: 231px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: #f0f2ff;
  border: 3px solid #bcbde7;
  border-radius: 0.5rem;
}
.SDP_plus_sign_div {
  width: 81.36px;
  height: 81.36px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 4px solid #bcbde7;
  font-size: 80px;
  font-weight: 300;
  color: #bcbde7;
}
.SDP_conttent {
  color: #232323;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px; /* 300% */
  letter-spacing: 1px;
  text-transform: uppercase;
}
.SDP_default_home {
  color: #2a2929;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 120% */
  letter-spacing: 1px;
}
.SDP_content_item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.25rem;
  align-items: flex-start;
  color: #2d2d2d;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 161.538% */
  letter-spacing: 1px;
}
.SPD_content_test {
  border-radius: 5px;
  border: 3px solid #bcbde7;
  background: #fff;
  padding: 3rem 1rem;
  text-align: center;
  width: 95%;
  height: 243px;
  flex-shrink: 0;
  color: #757575;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 92.308% */
  letter-spacing: 1px;
}

// Account Profile

.AP_container {
  border-radius: 10px;
  border: 2px solid #cecfdf;
  width: 100%;
  margin-top: 1.5rem;
}

// Saved Items

.SI_input_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
  }
}

// PUrchase History

.PH_links {
  display: flex;
  gap: 2rem;
  justify-content: flex-start;
  align-items: center;
  span {
    color: #5258d9;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: 12px; /* 80% */
    cursor: pointer;
  }
}
.PH_cards {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 2rem 0;
}
.PH_card {
  width: calc(100% - 2rem);
  padding: 1rem 1rem;
  height: 317px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.PH_card_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  width: calc(100% - 4rem);
}
.PH_card_1_1 {
  display: flex;
  gap: 2rem;
}

.PH_content_text_1 {
  color: #5258d9;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
  text-transform: capitalize;
}
.PH_content_text_2 {
  color: #000;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
}

.PH_card_2 {
  padding: 1rem 2rem;
  width: calc(100% - 4rem);
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}
.PH_card_2_2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.PH_card_2_1{
  height: 100%;
}
.PH_card_btn {
  width: 294px;
  height: 38px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 2px solid #5258d9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5258D9;
text-align: center;
font-family: Roboto;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 123.077% */
cursor: pointer;
}
.PH_card_btn:hover{
  background-color: #5258D9;
  color: white;
}

.archive_order{
  color: #252ABE;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}


// Social Engagement 

.SE_text{
  color: #000;
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 41px; /* 205% */
text-transform: capitalize;
}
.no_scroll::-webkit-scrollbar{
  display: none;
}

.card_title_new{
  width: 93px;
  color: #252ABE;
text-align: center;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
text-align :center;
line-height: 17px; /* 121.429% */
}

