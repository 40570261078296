.Review {
  width: calc(100% - 3rem);
  /* height: 593.805px; */
  flex-shrink: 0;
  /* background: linear-gradient(
    90deg,
    #252bbe -6.73%,
    #4047c7 40.92%,
    #813dec 82.09%
  ); */
  padding: 4rem 0 4rem 3rem;
}
.RV-1st {
  height: 46px;
  flex-shrink: 0;
  color: #fff;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.RV-2nd {
  height: 27.574px;
  flex-shrink: 0;
  color: #fff;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
}
.RV-3rd {
  margin: 5rem 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1rem;
}
.RV-3rd-1st {
  position: relative;
  width: calc(404.216px - 4rem);
  height: calc(280.342px - 4rem);
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  gap: 1.5rem;
}
.RV-profile {
  position: absolute;
  top: -40px;
  right: 25px;
  width: 84.731px;
  height: 82.412px;
  flex-shrink: 0;
  border-radius: 50%;
}
.RV-rating {
  padding-left: 2rem;
  width: 161.316px;
  height: 30.412px;
  flex-shrink: 0;
}
.RV-comment {
  padding-left: 1rem;
  width: 295.069px;
  color: #2c2c2c;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 135% */
  position: relative;
}
.RV-Name {
  padding-left: 1rem;
  /* width: 160.295px; */
  color: #2c2c2c;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 135% */
}
.RV-coma-img1 {
  position: absolute;
  width: 25.042px;
  height: 18.756px;
  flex-shrink: 0;
  top: -10px;
  left: -20px;
}
.RV-coma-img2 {
  position: absolute;
  width: 25.042px;
  height: 18.756px;
  flex-shrink: 0;
  bottom: -10px;
  right: -30px;
}

@media screen and (max-width: 500px) {
  .Review {
    width: calc(100% - 2rem);
    padding: 2rem 1rem 2rem 1rem;
  }

  .RV-1st {
    width: 100%;
    height: auto;
    font-size: 20px;
  }
  .RV-2nd {
    width: 100%;
    height: auto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
  .RV-3rd {
    width: 100%;
    flex-direction: column;
    gap: 3rem;
  }
  .RV-3rd-1st {
    width: auto;
    height: auto;
  }
  .RV-comment {
    width: auto;
    height: auto;
  }
}

@media screen and (max-width: 900px) {
  .RV-1st {
    font-size: 25px;
  }
  .RV-2nd {
    font-size: 20px;
  }
}
