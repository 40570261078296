.btn-success {
  color: #fff;
  background-color: #18b389;
  /* border: 1px solid #16a881; */
}

.btn {
  width: 80%;
  /* font-family: Roboto; */
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  padding: 0px 12px;
  font-size: 16px;
  height: 47px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 30px;
  background: var(--color-button);
  color: #ffffff;
}
/* Payment Bank  */
.P-btn-1 {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #b3b5e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(270.676px - 1.5rem);
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}
.P-btn-1-01 {
  font-family: Roboto Condensed;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}
.P-btn-1-01 > div {
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.P-btn-1-02 {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  display: flex;
  flex-direction: column;
}

.p-btn-saved {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  text-transform: capitalize;
}

/* PaymentButtonItem */
.item-P-btn-1 {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #b3b5e8;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: calc(100% - 0.5rem);
  padding: 0.5rem 0.25rem 0.5rem 0.25rem;
}
.item-P-btn-1-01-price {
  color: #000;
  text-align: center;
  font-family: Roboto Condensed;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  letter-spacing: -1px;
  text-transform: uppercase;
}

.item-P-btn-1-01 {
  font-family: "Roboto Condensed";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* text-transform: uppercase; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.25rem;
  height: 100%;
}
/* .item-P-btn-1-01 > div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */
.item-P-btn-1-02 {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  display: flex;
  flex-direction: column;
}

.item-saved-price-div {
  width: 182px;
  height: 21px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #b8f8e9;
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 141.667% */
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-saved-price-div > span {
  color: #252bbe;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  /* text-transform: capitalize; */
}
.span_price {
  color: #000;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  text-transform: capitalize;
}
.item-btn-1 {
  width: 100.782px;
  height: 21.925px;
  flex-shrink: 0;
  border-radius: 30px;
  background-color: var(--color-button);
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.5px; /* 112.5% */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  border: 0.5px solid var(--color-button);
}
.item-btn-1:hover {
  background-color: #fff;
  color: var(--color-button);
}
.item-btn-2 {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
}

/* PaymentButtonItemDesc */

.PBID {
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  width: 156px;
}
.PBID-1 {
  width: calc(156px - 2rem);
  height: calc(130px - 2.65rem);
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #252bbe;
  display: flex;
  gap: 0.85rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  /* padding-bottom: 1.5rem; */
}
.PBID-1-i {
  /* width: 100%; */
  display: flex;
  /* align-self: flex-end; */
  align-items: center;
  cursor: pointer;
}
.PBID-1-i > span {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  border: 1px solid #252bbe;
  border-radius: 50%;
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
  line-height: 10.5px; /* 116.667% */
  display: flex;
  justify-content: center;
  align-items: center;
}
.PBID-1-price {
  color: #000;
  text-align: center;
  font-family: Roboto Condensed;
  font-size: 27px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 88.889% */
  text-transform: uppercase;
}
.PBID-1-saved {
  width: 119px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #5258d9;
  background: #b8f8e9;
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 141.667% */
  text-transform: capitalize;
}
.PBID-1-saved > span {
  color: #252bbe;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  text-transform: capitalize;
}
.PBID-2 {
  width: 163px;
  height: 36px;
  flex-shrink: 0;
  background-color: var(--color-button);
  border-radius: 30px;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.5px; /* 96.429% */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  border: 1.5px solid var(--color-button);
}
.PBID-2:hover {
  background-color: #fff;
  color: #252bbe;
}
.PBID-3 {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
}
