// style={{width:"90%",marginTop:"90px",padding:"5% 5%"}}
.ex_picker_main {
  width: calc(100% - 10%);
  margin-top: 90px;
  padding: 5% 5%;
}
.Typewriter__wrapper{
  color: #252bbe;
  text-align: justify;
  height: calc(100% - 2rem);
  width: calc(100% - 4rem);
  padding: 1rem 2rem;
}

.ex_picker {
  width: calc(100% - 8%);
  padding: 2.5% 4% 5% 4%;
  border-radius: 5px;
  border: 2px solid #ebecf3;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.ex_pick_1 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  div {
    background: #dde1fe;
    border-radius: 50%;
    padding: 0.25rem 0.85rem;
    cursor: pointer;
    span {
      color: #252abe;
      font-family: Roboto;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 35px; /* 125% */
    }
  }
}
.ex_pick_3 {
  color: #000;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.68px;
}

.ex_pick_4 {
}
.ex_pick_5 {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px; /* 83.333% */
  letter-spacing: -0.5px;
}
.ex_pick_6 {
  max-width: 707px;
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: 38px; /* 152% */
}
.ex_pick_7 {
  width: auto;
  border-radius: 50px;
  background:var(--color-button);
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.75rem 2rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: 0.5s;
  border: 1px solid var(--color-button);
}
.ex_pick_7:hover{
  background: #fff;
  color: #252abe;
}

.ex_picker_tool_tip {
  position: absolute;
  right: 30px;
  bottom: 50px;
  background-color: #252bbe !important;
  border-radius: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    background-color: #252bbe !important;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 106.667% */
  }
}

.ex_modal_1 {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 64% */
}
.ex_modal_2 {
  max-width: 426px;
  color: #707070;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 138.889% */
}

// Product Picker Question Main

.ex_pick_pgination {
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;
}
.ex_pick_left_arrow {
}
.ex_pick_pgination_main {
  display: flex;
  background-color: #dee0fc;
  border-radius: 25px;
  div {
    cursor: pointer;
    // border-radius: 25px;
    color: #8386ca;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0.5rem 1.2rem;
  }
}
.ex_pick_exit {
  color: #252bbe;
  font-family: Roboto Condensed;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 63.158% */
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
}
.ex_pick_Question {
  color: #000;
  font-family: Roboto;
  font-size: 27px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ex_pick_see_all {
  color: #252bbe;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ex_pick_1_img_div {
  display: flex;
  gap: 2rem;
}
.ex_pick_1_img_div_img {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-content: center;
  gap: 2rem;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #252bbe;
    text-align: center;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.ex_pick_img_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 124px;
  height: 124px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #252bbe;
}
.ex_pick_next {
  width: 248.819px;
  height: 57.069px;
  flex-shrink: 0;
  border-radius: 50px;
  background: var(--color-button);
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  border: 1px solid var(--color-button)
 
}
.ex_pick_next:hover{
  color: #252bbe;
  background-color: #fff;

}

.ex_pick_check_box_content {
  color: #252bbe;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
.ex_pick_see_all_ans {
  color: #252bbe;
  text-align: center;
  font-family: Roboto Condensed;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 70.588% */
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
}

.ex_pick_3_img_div_img {
  width: calc(220.391px - 2rem);
  height: 58.148px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #252bbe;
  background: #fff;
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 106.667% */
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;
  cursor: pointer;
}
.ex_pick_6_content_div_1 {
  width: 521.73px;
  height: 356.574px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 2px solid #252abe;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0rem;
}

.ex_pick_content_title {
  display: flex;
  width: calc(100% - 2px);
  height: fit-content;
  justify-content: flex-end;
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 200% */
}
.ex_pick_6_content_div_2 {
  width: 521.73px;
  height: 356.574px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 2px solid #252abe;
  background: #fff;
}
.ex_pick_content_div_1 {
  width: calc(343.624px - 2px);
  min-height: calc(194.552px - 2px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 1px;
  border: 1px solid #a1a0a0;
  background: #dde1fe;
  display: flex;
  overflow: hidden;
}

.ex_pick_content_div_2 {
  width: calc(324.674px - 2px);
  height: calc(181.919px - 2px);
  flex-shrink: 0;
  border-radius: 1px;
  border-top: 1px solid #a1a0a0;
  border-right: 1px solid #a1a0a0;
  background: #dde1fe;
}
.ex_pick_content_div_3 {
  width: calc(304.461px - 2px);
  height: calc(170.549px - 2px);
  flex-shrink: 0;
  border-radius: 1px;
  border-top: 1px solid #a1a0a0;
  border-right: 1px solid #a1a0a0;
  background: #dde1fe;
}
.ex_pick_content_div_4 {
  width: calc(284.248px - 2px);
  height: calc(159.179px - 2px);
  flex-shrink: 0;
  border-radius: 1px;
  border-top: 1px solid #a1a0a0;
  border-right: 1px solid #a1a0a0;
  background: #dde1fe;
}
.ex_pick_content_div_5 {
  width: calc(264.034px - 2px);
  height: calc(149.072px - 2px);
  flex-shrink: 0;
  border-radius: 1px;
  border-top: 1px solid #a1a0a0;
  border-right: 1px solid #a1a0a0;
  background: #dde1fe;
}
.ex_pick_content_div_6 {
  width: calc(245.085px - 2px);
  height: calc(136.439px - 2px);
  flex-shrink: 0;
  border-radius: 1px;
  border-top: 1px solid #a1a0a0;
  border-right: 1px solid #a1a0a0;
}
.ex_pick_content_div_7 {
  width: calc(224.871px - 2px);
  height: calc(125.069px - 2px);
  flex-shrink: 0;
  border-radius: 1px;
  border-top: 1px solid #a1a0a0;
  border-right: 1px solid #a1a0a0;
  color: #ffffff !important;
}
.ex_pick_content_div_8 {
  width: calc(204.658px - 2px);
  height: calc(114.962px - 2px);
  flex-shrink: 0;
  border-radius: 1px;
  border-top: 1px solid #a1a0a0;
  border-right: 1px solid #a1a0a0;
  color: #ffffff !important;
}
.ex_pick_content_div_9 {
  border-radius: 1px;
  border-top: 1px solid #a1a0a0;
  border-right: 1px solid #a1a0a0;
  color: #ffffff !important;
  width: calc(179.392px - 2px);
  height: calc(101.066px - 2px);
  flex-shrink: 0;
}
.ex_pick_content_div_10 {
  width: calc(163.38px - 2px);
  height: calc(91.364px - 2px);
  flex-shrink: 0;
  border-radius: 1px;
  border-top: 1px solid #a1a0a0;
  border-right: 1px solid #a1a0a0;
  color: #ffffff !important;
}
.ex_pick_content_div_11 {
  width: calc(163.38px - 2px);
  height: calc(91.364px - 2px);
  color: #ffffff !important;
  flex-shrink: 0;
  border-radius: 1px;
  border-top: 1px solid #a1a0a0;
  border-right: 1px solid #a1a0a0;
}
.ex_pick_content_div_12 {
  width: calc(132.649px - 2px);
  height: calc(74.536px - 2px);
  flex-shrink: 0;
  color: #ffffff !important;
  border-radius: 1px;
  border-top: 1px solid #a1a0a0;
  border-right: 1px solid #a1a0a0;
}

// Div Data for Point No . 6

// Product List CSS
.ex_product_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ex_product_main_top {
  display: flex;
  width: calc(100% - 10%);
  justify-content: space-between;
  padding: 5% 5% 2% 5%;
}
// .ex_pick_left_arrow{
//   height: ;
//   align-items: center;
// }
.ex_product_main_center {
  display: flex;
  flex-direction: column;
  gap: .25rem;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.ex_product_main_right {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.try {
  width: 146.4px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 50px;
  border: 2px solid #252abe;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 156.25% */
  transition: 0.5s;
}
// .try:hover{
//   color: #fff;
//     background-color: #252abe;

// }
.ex_product_bottom {
  // width: calc(100% - 10%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 0%;
  background: #edefff;
}

// Circle bar css

:root {
  --progress-bar-width: 120px;
  --progress-bar-height: 120px;
  --font-size: 2rem;
}

.circular_progress {
  // width: var(--progress-bar-width);
  // height: var(--progress-bar-height);
  height: 92px;
  width: 92px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner_circle {
  position: absolute;
  width: calc(var(--progress-bar-width) - 30px);
  height: calc(var(--progress-bar-height) - 30px);
  border-radius: 50%;
  background-color: #fff;
}

.percentage {
  position: relative;
  font-size: var(--font-size - 1.5rem);
  // color: rgb(0, 0, 0, 0.8);
  // color: #fff;
}
.match {
  position: relative;
  color: #252bbe;
  font-family: Roboto Condensed;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.img_ai{
  width: 200px;
  height: 80px;
  object-fit: fill;
}

@media screen and (max-width:500px) {
  .ex_pick_5{
    font-size: 20px;
    line-height: 25px;
  }
  .ex_pick_6{
    font-size: 17px;
    line-height: 25px;
  }
  .ex_picker_main{
    margin-top: 60px;
    padding: 5% 2% ;
    width: calc(100% - 4%);
  }
  .expert_main_img{
    height: 80px;
    width: 100px;
    object-fit: fill;
  }
  .ex_pick_pgination{
    gap: 1rem;
  }
  .ex_pick_Question{
    font-size: 18px;
  }
  .ex_pick_1_img_div{
    flex-wrap: wrap;
    justify-content: center;
  }
  .ex_pick_see_all{
    font-size: 15px;
  }
  .ex_pick_1_img_div_img{
    text-align: center;
  }
  .ex_pick_6_content_div_1{
    width: auto;
    height: auto;
    border: none;
  }
  .ex_picker {
    width: calc(100% - 4%);
    padding: 2.5% 2% 5% 2%;
  }
  .ex_product_main_center {
    gap: .25rem;
  }
  .ex_product_main_center > div{
 text-align: center;
  }
}