.main_expert_div_heading {
    color: #4148C8;
    font-family: "Roboto Condensed";
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    /* 40% */
    text-transform: capitalize;
}

.main_expert_component {
    background: #F5F5F5;
    padding: 10px;
    height: fit-content;
    width: 100vw;
}

.expert_picker_inner_div {
    border-radius: 10px;
    background: white !important;
    width: 80vw;
    height: fit-content;
    padding: 25px;
    margin: 3% 0;
}

.expert_picker_inner_first {}

.expert_picker_first_heading {
    color: #5258D9;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 45px;
    /* 225% */
    letter-spacing: 1px;
    text-transform: uppercase;
}

.expert_picker_first_desc {
    color: #000;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    /* 321.429% */
    letter-spacing: 1px;
}

.expert_picker_search_form {
    display: flex;
    align-items: center;
    gap: 15px;
}

.search_form_expert {
    border-radius: 5px;
    border: 1px solid #ABAEB1;
    width: 332.594px;
    height: 30px;
    flex-shrink: 0;
}

.search_form_button {
    border-radius: 17.174px;
    background: #5258D9;
    width: 147px;
    height: 34px;
    border: none;
    flex-shrink: 0;
}

.form_picker_expert {
    display: flex;
    align-items: center;
    gap: 15%;
}

.expert_picker_filter {
    display: flex;
    align-items: center;
    gap: 15px;

}

.filter_description {
    color: #252BBE;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.filter_select {
    border-radius: 50px;
    border: 1px solid #252BBE;
    background: #FFF;
    width: 238px;
    height: 30px;
    flex-shrink: 0;
}

.filter_options {}

.expert_picker_inner_second {}

.data_table_expert_picker {}

.expert_picker_inner_second {
    border-radius: 5px;
    border: 2px solid #CECFDF;
    background: #F0F2FF;
    height: 60vh;
    overflow: scroll;
    margin: 40px 0;
}

.expert_picker_inner_second::-webkit-scrollbar-thumb {
    width: 12px;
    height: 55px;
    flex-shrink: 0;
    border-radius: 50px;
    background: #5258D9;
}

/* Handle on hover */
.expert_picker_inner_second::-webkit-scrollbar-thumb:hover {
    background: #3a3ea3;
    /* Adjust the hover background color as needed */
}

table {
    border-collapse: collapse;
    width: 100%;
    color: #4148C8;
}

th,
td {
    text-align: center;
    padding: 8px;
    color: #4148C8;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 100% */
    text-transform: capitalize;

}

tr {
    display: grid;
    grid-template-columns: repeat(5, 3fr);
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Existing styles */

/* Media query for smaller screens */
@media (max-width: 1268px) {
    .form_picker_expert {
        flex-direction: column;
    }

    /* Adjust other styles as needed for smaller screens */
}

/* Media query for even smaller screens */
@media (max-width: 480px) {
    .main_expert_component {
        padding: 30px;
    }

    .expert_picker_inner_div {
        width: 95vw;
        padding: 10px;
    }

    .expert_picker_first_heading {
        font-size: 18px;
        line-height: 30px;
    }

    .expert_picker_first_desc {
        font-size: 12px;
        line-height: 25px;
    }

    .filter_description {
        font-size: 14px;
        line-height: 20px;
    }

    /* Adjust other styles as needed for even smaller screens */
}