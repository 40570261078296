.Dropdown {
  width: 245px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: auto;
  border-radius: 20px;
  position: relative;
  border: 1.5px solid #252bbe;
  /* background: #f00; */
  background: #f0f2ff;
  margin-top: 0.12rem;
  gap: 14.72px;
  padding: 0.65rem 1.5rem;
  z-index: 1;
  p {
    color: #252abe;

    font-family: Roboto;
    font-size: 16px;
    font-weight: 600 !important;
    line-height: normal;
  }
}
.PD_dropdown {
  position: absolute;
  left: -3px;
  top: -0.1px;
  z-index: 0;
  width: 202px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.35rem !important;
  border-radius: 20px;
  border: 1.5px solid #252bbe;
  /* background: #f00; */
  background: #f0f2ff;
  color: #252abe !important;
  font-family: Roboto !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: normal;
  cursor: pointer;
  padding: 3rem 1.4rem 0.65rem 2rem;
  /* padding: 3rem 1.5rem 0.65rem 1.5rem; */
  /* margin-left: 12px; */
  p {
    text-align: left !important;
  }
}

.inp_main {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.inp_label {
  margin-bottom: 5px;
  font-weight: 700;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  color: #6f6f6f;
  line-height: 24px;
}
.drop_select {
  border-radius: 2px;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 28px;
  margin: 0;
  outline: none;
  border: 1px solid #b3b5e8 !important;
  height: 35px;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  font-family: "IBM Plex Sans", sans-serif;
  background-image: url("../../assets/arrowDown.svg");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}

/* Address DropDown */

.address_Dropdown {
  /* width: calc(100% - 2px); */
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: auto;
  border-radius: 5px;
  position: relative;
  border: 2px solid gray;
  background: #f0f2ff;
  margin-top: 0.12rem;
  margin-left: 0.1rem;
  gap: 14.72px;
  padding: 0.65rem 1.4rem;
  z-index: 1;
  overflow-y: hidden;
  p {
    color: #252abe;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600 !important;
    line-height: normal;
  }
}
.address_PD_dropdown {
  overflow-y: hidden;
  position: absolute;
  left: 0px;
  /* top: -1px; */
  z-index: 0;
  /* width: 202px; */
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.35rem !important;
  border-radius: 5px;
  /* border: 2px solid gray; */
  border: 2px solid #fff;
  background: #f0f2ff;
  color: #252abe !important;
  font-family: Roboto !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: normal;
  cursor: pointer;
  padding: 1rem 1.5rem 0.65rem 1.5rem;
  p {
    text-align: left !important;
  }
}

@media screen and (max-width: 500px) {
  .Dropdown {
    width: 200px !important;
  }
  .DWM_2_sort > div > div > div > p {
    font-size: 15px;
  }
  .PD_dropdown {
    width: 200px !important;
  }
}
