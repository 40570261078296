.Queue {
  margin-top: 90px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main {
  width: 100%;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
.title {
  color: #020a26;
  text-align: center;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 48% */
  text-transform: uppercase;
}

.title_2 {
  color: #252abe;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 60% */
  text-transform: capitalize;
}
.title_3 {
  color: #525252;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
}
.title_4 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  div {
    padding: 0.25rem 1rem;
    // width: 200px;
    border-radius: 50px;
    background: #5258D9;
    // background: #C2C5FA;
    // background: #e284bf;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: 0.7px solid #5258D9;
    transition: 0.5s;
  }
  div:hover{
  background: #fff;
  color:#252abe ;

  }
}
.purchase_btn_div{
display: flex;
background-color: red;
align-items: flex-start;
}
.purchase_btn_div_btn1{
border: 1px solid #5258D9;
padding: 1rem;
display: flex;
background-color: #000;
}
.steps {
  width: 60%;
}
.steps_que {
  display: flex;
  justify-content: space-between;
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}
.second_main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  width: calc(100% - 6%);
  background-color: #edefff;
  padding: 1rem 3%;
  overflow-y: scroll;
  height: 100vh;
}
.second_main::-webkit-scrollbar {
  display: none;
}
.cards {
  display: flex;
  gap: 0.25rem;
  width: fit-content;
  // justify-content: center;
  flex-direction: column;
  width: auto;
  // flex-wrap: wrap;
}

.card_slider {
  max-width: calc(660.007px - 2rem);
  padding: 1rem;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.card_slider:hover{
  background-color: #c1c1c122;
}
.card_1 {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.card_2 {
  display: flex;
  gap: 1rem;
}
.img {
  height: 150px;
  width: 200px;
  object-fit: contain;
}
.card_content {
  // padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.card_content_1 {
  color: #252abe;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}
.card_content_2 {
  color: #252abe;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.card_content_3 {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 0 0.5rem; 
  // width: calc(84.516px - 1rem);
  width: 100px;
  height:30px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  color: #3f3636;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  overflow: hidden;
}
.Slider_qua {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.card_3 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  div {
    // width: 35%;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.price {
  position: sticky;
  top: 0;
  width: calc(536.338px - 4rem);
  height: fit-content;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
}
.price_main {
  width: calc(100% - 4%);
  padding: 0 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.price_2 {
  width: calc(100% - 6rem);
  padding: 0 3rem;
  border-top: 1px solid #b3b5e8;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.price_amt {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  margin-top: 1rem;
  color: #1b1c6d;
  font-family: Roboto Condensed;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  text-transform: uppercase;
}
.price_btn {
  border-radius: 30px;
  background: var(--color-button);
  width: 270px;
  height: 45px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 106.667% */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  transition: 0.5s;
  border: 2px solid var(--color-button);
}
.price_btn:hover{
  color: #252bbe;
  background: #fff;
}
.price_content {
  width: 300px;
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  text-align: left;
}
.price_breack {
  width: auto;
  border-bottom: 1px solid #252bbe;
  padding-bottom: 1rem;
  color: #252bbe;
  font-family: "Roboto Condensed";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 66.667% */
  text-transform: uppercase;
  margin-bottom: 2rem;
  margin-left: 20px;
}
.price_1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.price_desc {
  
    width: 100%;
 
}
.price_desc_row{
  display: flex;
  gap: 10px;
  height: 25px;
}
// table {
//   border-collapse: collapse;
//   border-spacing: 0;
//   width: 100% ;
//   // background-color: red;
//   // border: 1px solid #ddd;
// }

// th,
// td {
//   text-align: left;
//   // padding: 8px;
//   text-align: center;
// }
// tr {
//   // height: 50px;
//   height: 25px;
// }
.td_2_1 {
  color: #020a26;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  text-align: left;
  flex: 1;
}
.td_3_1 {
  color: #000;
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  display: flex;
  justify-content: flex-end;
}
.td_1_1 {
  min-width: 20px;
}
.td_underLine {
  text-align: left;
  text-decoration: underline;
  color: #252bbe;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  cursor: pointer;
  text-align: left;
  flex: 1;
}

// Manu Facturing Component

.manu_agree {
  width: calc(100% - 5%);
  padding: 2% 2.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
}
// .manu_title {
//   color: #252bbe;
//   text-align: center;
//   font-family: Roboto;
//   font-size: 15px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 24px; /* 160% */
//   text-transform: uppercase;
// }
.manu_title {
  color: #5258d9;
  text-align: left;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 184.615% */
}
.manu_img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.agree_img_content {
  position: absolute;
  top: 20%;
  right: -36%;
  width: 100px;
  width: 54px;
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  text-transform: capitalize;
}
.agree_1_btn {
  width: 137px;
  height: 33px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 1px solid var(--color-button);
  background: #fff;
  color: #5258d9;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;

}
.agree_1_btn:hover{
color: #fff;
background-color: var(--color-button);
}
.img_div_manu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
}
.agreement {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  color: #252bbe;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  letter-spacing: -0.5px;
}

.terms {
  display: flex;
  gap: 1rem;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  div {
    color: #252bbe;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; /* 135.714% */
  }
}
.btn {
  width: 270px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 30px;
  background: var(--color-button);
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 106.667% */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  border: 2px solid var(--color-button);
}
.btn:hover{
background-color: #fff;
color:#252bbe;
}

// user Agreement Css

.manu_user_agree {
  width: calc(100% - 5%);
  padding: 2% 2.5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;
  height: 200px;
  overflow-y: scroll;
}
.manu_user_agree::-webkit-scrollbar {
  display: none;
}

.manu_brand_div {
  width: calc(307px - 2rem);
  padding: 0 1rem;
  height: 87px;
  flex-shrink: 0;
  border-radius: 10px;
  background: linear-gradient(138deg, #4047c7 48.47%, #813dec 95.25%);
  stroke-width: 1px;
  stroke: #252bbe;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.agree_img_content_2 {
  width: 115.566px;
  // height: 33.523px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

// Payment Component

.pay_card {
  width: calc(660.007px - 6rem);
  padding: 1rem 3rem;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
.pay_title {
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 138.889% */
  letter-spacing: -0.5px;
}
.input_2 {
  width: 100%;
  display: flex;
  gap: 2%;
}
.pay_fee {
  color: #252bbe;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.bank_details_title {
  color: #5258d9;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 166.667% */
  letter-spacing: -0.5px;
}
.banks_details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.pay_banks {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.pay_bank {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: calc(100% - 3rem);
  gap: 1rem;
  padding: 0 1.5rem;
  border-radius: 8px;
  border: 1px solid #b3b5e8;
  background: #fff;
  div {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    div {
      color: #292ebb;
      text-align: right;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
    }
  }
}
.ship_add {
  color: #252bbe;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}

// Warrent Component

.close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.war_content {
  width: calc(100% - 10%);
  padding: 5% 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-align: center;
}
.war_title {
  text-align: center;
  color: #252bbe;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
.war_2 {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.5px;
}
.war_3 {
  color: #252bbe;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.5px;
}
.war_4 {
}
.war_details {
  color: #252bbe;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  text-decoration-line: underline;
}

// Purchase Component

.pur_price {
  color: #252bbe;
  font-family: "Roboto Condensed";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  text-transform: uppercase;
}
.pur_price_2 {
  display: flex;
  gap: 1rem;
}
.pur_price_qty {
  color: #252abe;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
}
.pur_price_del_type {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.25rem;
}

.order {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.order_details {
  padding: 2rem;
  display: flex;
  gap: 2rem;
  justify-content: space-evenly;
}
.order_d_1 {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
}
.order_d_1_1 {
  cursor: pointer;
}
.order_d_1_2 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: #626262;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 130.769% */
}
.order_d_1_2_title {
  color: #252bbe;
  font-family: "Roboto";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 166.667% */
}
.order_manu_agree {
  display: flex;
  gap: 1.5rem;
  margin-left: 3rem;
}
.order_manu_agree_1 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: #626262;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}

.order_manu_agree_1_title {
  cursor: pointer;
  color: #252bbe;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 166.667% */
  text-decoration-line: underline;
}

.modal {
  width: 80% !important;
}

@media screen and (max-width: 1250px) {
  .second_main {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  .cards {
    // width: calc(100% - 10%);
    width: 100%;
  }
  .price {
    width: calc(100% - 4rem);
    position: static;
  }
  .card {
    width: calc(100% - 2rem);
  }

  // pay Card media

  .pay_card {
    width: calc(100% - 6rem);
  }
}

@media screen and (max-width: 600px) {
  .card_3 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
  }
  .input_2 {
    flex-direction: column;
    gap: 1rem;
    div {
      width: 90% !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .second_main {
    width: calc(100% - 2rem);
    padding: 1rem;
  }
  .title {
    font-size: 18px;
  }
  .cards {
    width: 100%;
  }
  .card {
    width: calc(100% - 2rem);
  }
  .card_2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  .img {
    width: 80%;
  }
  .card_content {
    padding-top: 0;
  }
  .price_main {
    width: 100%;
    padding: 0;
  }
  .price_2 {
    gap: 1rem;
  }
  .manu_title {
    margin-top: 1.5rem;
  }
  .modal {
    width: 95% !important;
  }
  .terms {
    div {
      gap: 0.5rem;
      text-align: left;
    }
  }
}

// Queue Slider CSS
// .QD_blur{

// }
// .QD {
//   position: fixed;
//   z-index: 15;
//   right: 0;
//   top: 0;
//   width: 536.751px;
//   // max-height: 600px;
//   height: 100vh;
//   background-color: white;
//   overflow-y: scroll;
// }

// To achieve the desired effect of having the outside area of the .QD element with reduced opacity, you can use the .QD_blur class. Here's an example of how you can modify your CSS:

// css
// Copy code
.QD_blur {
  position: fixed;
  z-index: 10; /* Lower z-index than .QD to be behind it */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black; /* Adjust the opacity here */
  opacity: 0.3;
}

.QD {
  position: fixed;
  z-index: 15;
  right: 0;
  top: 0;
  width: 536.751px;
  height: 100vh;
  // background-color: white;
  // overflow-y: scroll;
}
.QD::-webkit-scrollbar {
  display: none;
}
.QD_first {
  height: 50px;
  border-bottom: 6px solid #252bbe;
  width: calc(100% - 5%);
  padding: 1% 2.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 20;
  background-color: #fff;

  div {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
    div {
      color: #252bbe;
      text-align: center;
      font-family: Roboto;
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
.QD_count {
    border: 2.5px solid #fff;
  position: absolute;
  top: -12px;
  right: -8px;
  background-color: #b8f8e9 ;
  padding: 0.15rem 0.45rem !important;
  border-radius: 50%;
  color: white;
  font-family: Roboto;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  // height: 25px;
  //     width: 25px;
      justify-content: center;
      align-items: center;
      text-align: center;
      display: flex;
}
.QD_2 {
  width: calc(100% - 10%);
  height: 80vh;
  background: #edefff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 5%;
  overflow-y: scroll;
  margin-top: 89px;
}
.QD_2::-webkit-scrollbar {
  display: none;
}
.card_silder_2 {
  display: flex;
  gap: 1rem;
}
.card_silder_content {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  gap: 0.5rem !important;
}
.card_silder_content_1 {
  color: #252abe;
  text-align: left;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 153.846% */
}
.card_silder_content_2 {
  color: #252abe;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.card_silder_content_3 {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: left;
  gap: 1rem;
  div {
    color: #030002;
    font-family: "Roboto Condensed";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 100% */
    text-transform: uppercase;
    div {
      color: #252bbe;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
.QD_3 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
}
.card_slider_btn {
  height: 50px;
  width: 70%;
  border-radius: 30px;
  background: var(--color-button);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 80% */
  cursor: pointer;
}
.card_slider_3_2 {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.card_slider_btn_content_1 {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}
.card_slider_btn_content_2 {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
}
.card_silder_img {
  width: 189.197px;
  height: 124.621px;
  flex-shrink: 0;
}

// PurchaseComponent CSS

.PC_price {
  color: #252bbe;
  font-family: Roboto Condensed;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  text-transform: uppercase;
}
.PC_2nd {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.PC_2nd_2 {
  color: #252abe;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
}
.PC_2nd_3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.PC_2nd_3_1 {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}
.PC_2nd_3_2 {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
}
.PC_3rd {
  width: calc(100% - 1rem);
  padding: 1rem 0.5rem;
  display: flex;
  gap: 2rem;
}
.PC_3rd_1 {
  display: flex;
  gap: 1rem;
}
.PC_3rd_1_1 {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  color: #626262;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 130.769% */
}
.PC_3rd_1_1_title {
  color: #252bbe;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 166.667% */
}
.PC_4th {
  display: flex;
  gap: 1rem;
}
.PC_4th_1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #626262;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
.PC_4th_1_title {
  color: #252bbe;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 166.667% */
  text-decoration-line: underline;
}
.PC_btn_div {
  background-color: #edefff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
  div {
    border-radius: 30px;
    padding: 0.65rem 3rem;
    cursor: pointer;
  }
}
.PC_btn_1 {
  background-color: var(--color-button);
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  border: 2px solid var(--color-button);
  transition: 0.5s;
}
.PC_btn_1:hover{
  background-color: #fff;
  color: #252bbe;
}
.PC_btn_2 {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  border: 2px solid var(--color-button);
  transition: 0.5s;
}
.PC_btn_2:hover {
  background-color: var(--color-button);
  color: #fff;
}

// New Changes CSS
// Heading CSS Changes

.cards_heading {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}
.cards_heading_2 {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
}
.cards_heading_number {
  height: 29.582px;
  width: 29.582px;
  border-radius: 50%;
  background-color: #5258d9;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  font-family: Roboto;
}
.cards_heading_title {
  color: #5258d9;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 80% */
  letter-spacing: 1px;
  text-transform: uppercase;
}
.queue_add_ship_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add_select_title {
  color: #5258d9;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 160% */
}
.add_add_btn {
  display: flex;
  gap: 0.5rem;
  padding: 0.31rem 1rem;
  justify-content: center;
  border-radius: 4px;
  background: #5258d9;
  align-items: center;
  cursor: pointer;
}
.plus_icon_white {
  height: 15px;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  height: fit-content;
  border-radius: 50%;
}
.ship_btn_content {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 208.333% */
}
.add_drop {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5;
  justify-content: space-between;
  border: 2px solid #bcbde7;
  border-radius: 5px;
  background: #f0f2ff;
  cursor: pointer;
}
.add_drop_main {
  display: flex;
  width: calc(100% - 1.02rem);
  align-items: flex-start;
  gap: 0.5rem;
  justify-content: space-between;
  background: #f0f2ff;
  padding: 1rem 0.51rem;
  cursor: pointer;
}
.add_drop_1 {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.add_drop_title {
  color: #2a2929;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
}
.add_drop_content {
  color: #2d2d2d;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.payment_title {
  color: #5258d9;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 178.571% */
}
.card_table {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.card_tr {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #7478dd;
  color: #5258d9;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  text-transform: capitalize;
}

.card_td_1 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card_td_2 {
  flex: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card_td_3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
}
.card_td_4 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
}

.card_td_2_1 {
}
.card_td_2_1 {
  color: #000;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 115.385% */
  text-transform: capitalize;
}

.add_card_options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    color: #5258d9;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 160% */
  }
}
.card_add_btn {
  width: 270px;
  height: 45px;
  border-radius: 30px;
  background: #5258d9;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 106.667% */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.card_cvv {
  border: none;
  border-bottom: 1px solid black;
  border-radius: 5px;
  background-color: rgb(237, 239, 255);
  width: 30px;
  padding: 0.15rem 0.5rem;
}
.card_cvv:focus {
  outline: none;
}

// Manu View Component css
.MV_main {
  // min-width: 628px;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.MV_2nd {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // width: calc(100% - 3rem);
  padding: 1.5rem;
  border-radius: 5px;
  background: #f0f2ff;
  gap: 1rem;
}
.MV_2nd_title {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 85.714% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.MV_2nd_contents {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: .5rem;
}
.MV_2nd_content {
  display: flex;
  gap: 1rem;
}
.MV_2nd_cont_key {
  color: #252bbe;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 180% */
  min-width: 130px;
}
.MV_2nd_cont_value {
  color: #252bbe;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 192.857% */
}
.MV_3rd {
  display: flex;
  gap: 0.85rem;
  justify-content: flex-start;
  width: calc(100% - 3rem);
  padding: 1.5rem 1.5rem;
  border-radius: 10px;
  border: 2px solid #c0c4ff;
  background: #fff;
}

.MV_3rd_img {
}
.MV_3rd_2nd {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
}
.MV_3rd_2nd_title {
  color: #434343;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 75% */
  text-transform: capitalize;
}
.MV_3rd_2nd_content {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: capitalize;
}
.MV_3rd_btn_div {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.MV_3rd_btn {
  width: 110px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #5258d9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
  span {
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 106.667% */
  }
}
.add_1{
  margin-left: 3rem;
            display: flex;
            flex-direction: row;
            gap: 1rem;
            flex-wrap: wrap;
            margin-top: 1rem;
}
.pay_bill{
  width: 40%;
}

@media screen and (max-width:500px) {
  .pay_bill{
    width: 100% !important;
  }
  .queue_add_ship_div{
  flex-direction: column-reverse;
  }
  .pay_card{
    width: calc(100% - 0rem);
    padding: 1rem 0rem ;

  }
  .add_1{
    margin-left: 0;
  }
  .QD{
    width: 80%;
    background-color: #000;
  }
    .QD_header{
      background-color: red;
      display: flex;
height: 100%;
width: 100%;
    }
  .QD_first{
    width: auto;
    background-color: red;
    div{
      div{
        font-size: 15px;
      }
    }
  }
  .QD_2{
    // width: 100%;
  }
  .card_silder_img{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 220px;
    object-fit: fill;
  }
  .card_silder_2{
    gap: 0;
    flex-direction: column;
  }
  .QD_3{
    flex-direction: column;
    gap: .25rem;
  }
  .card_slider_btn{
    height: 35px;
  }
  .card_slider_3_2{
width: 100%;
  }
  .Queue {
    margin-top: 45px;
  }
  .img_div_manu{
flex-wrap: wrap;
  }
  .manu_brand_div{
    width: calc(100% - 2rem);
  }
  .add_drop_1{
    height: 50px;
  }
}