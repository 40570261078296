.FT {
  width: calc(100% - 10rem);
  padding: 3rem 5rem;
  border-top: 10px solid #b8f8e9;
  flex-shrink: 0;
  background: linear-gradient(
    182deg,
    #252bbe -205.02%,
    #4047c7 1.03%,
    #813dec 179.07%
  );
  display: flex;
  flex-direction: column;
  column-gap: 2rem;
}
.FT-logo{
  margin-bottom: 1rem;
}
.FT-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  margin-top: 1.5rem;
  border-top: 1px solid white;
  color: #ffffff;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.FT-bot-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}
.FT-links {
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2.8rem;
}
.FT-links-1st {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  align-items: flex-start;
  /* flex: 1; */
  width: fit-content;
  flex: 1;
}
.links-1st-01 {
  color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
}

.links-1st-02 {
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.links-1st-03 {
  display: flex;
  justify-content: space-around;
  gap: 0;
}
.links-input {
  width: 6rem;
  padding: 0.45rem 1rem;
  border: 1px solid #fff;
  border-right: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: none;
  border-bottom-right-radius: none;
  color: #ffffff;
  font-family: Roboto;
  background-color: transparent;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.links-input:focus {
  outline: none;
}
.links-input::placeholder {
  color: #fff;
}

.links-btn {
  border: none;
  cursor: pointer;
  padding: 0.45rem 1rem;
  border-top-left-radius: none;
  border-bottom-left-radius: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #4347c9;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.links-1st-head {
  color: #ffffff;
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 164.706% */
}
.links-1st-content {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}
.content-left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.content-item {
  color: #ffffff;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 215.385% */
}
.content-item > div {
  cursor: pointer;
  color: #fff;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 215.385% */
}
.content-item > div:hover {
  font-weight: bold;
}

@media screen and (max-width: 500px) {
  .FT {
    width: calc(100% - 2rem);
    padding: 3rem 1rem;
    justify-content: center;
  }
  .FT-links {
    flex-direction: column;
  }
  .FT-bottom {
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
    text-align: left;
  }
  .FT-bot-right {
    flex-direction: column;
  }
}
