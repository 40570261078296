.PC {
  width: 100%;
  margin-top: 3rem;
  background: linear-gradient(
    0deg,
    #252bbe -32.6%,
    #4047c7 52.17%,
    #813dec 125.41%
  );
  /* padding: 2rem 3rem; */
  display: flex;
  flex-direction: column;
  /* gap: 1.5rem; */
  justify-content: flex-start;
}
.PC1 {
  /* width: calc(100% - 6rem); */
  /* width: 100%; */
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  /* background-color: red; */
}
.PC-img-div_main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  height: 200px;

  width: 125px;
}
.PC-1 {
  color: #fff;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.PC-2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  cursor: pointer;
}
.PC-circle {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.PC-img-div {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: fill;
  overflow: hidden;
}

.PC-img {
  width: 97px;
  height: 97px;
  z-index: 0;
  object-fit: contain;
}
.PC-img:hover {
  width: 102px;
  height: 102px;
}
.PC-item-desc {
  text-align: center;
  color: white;
  font-size: 16px;
  word-wrap: wrap;
  font-weight: 400;
  font-family: Roboto;
  text-transform: capitalize;
  line-height: 18px; /* 75% */
}

@media screen and (max-width: 500px) {
  .PC-img-div_main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
    height: 190px;

    width: 100px;
  }
  .PC-img-div {
    width: 90px;
    height: 90px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .PC-1 {
    color: #fff;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .PC {
    width: 100%;
    /* width: calc(100% - 3rem); */
    margin-top: 0.1rem;
    /* background-color: #252bbe; */
    padding: 0.5rem 0.5rem;
  }
  .PC-2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex: 1;
    gap: 0.2rem;
    cursor: pointer;
  }
  .PC-img {
    height: 80%;
    width: 80%;
    object-fit: contain;
  }
  .PC-item-desc {
    text-align: center !important;
    font-size: 12 !important;
  }
}
