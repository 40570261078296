.RP_main {
  width: calc(100% - 30%);
  padding: 2% 15%;
  /* background-color: gray; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.RP_1st_title {
  color: #3d40b5;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px; /* 116.667% */
}
.RP_1st_right {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 214.286% */
  text-transform: uppercase;
}
.item {
  width: calc(100% - 2rem);
  padding: 1rem 1rem 3rem 1rem;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
}
.item_left > img {
  max-height: 200px;
  max-width: 150px;
  object-fit: contain;
}

.item_right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.title {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px; /* 85% */
}
.price {
  color: #4547ca;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.price > span {
  font-weight: 500;
}
.delivery {
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.cancle {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  cursor: pointer;

  /* justify-content: center; */
}
.cancleIcon {
  width: 40px;
  height: 40px;
}
.cancleOrder {
  display: flex;
  align-items: center;
  height: 45px;
  width: 100%;
  color: #000;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 112.688%; /* 16.903px */
  border-bottom: 1px solid #dadada;
}
.feedback {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 58px;
}
.feed_item1 {
  height: 50px !important;
  width: 50px !important;
  object-fit: cover !important;
}
.feed_item2 {
  height: 25px !important;
  width: 25px !important;
  object-fit: cover !important;
}
.feed_content {
  color: #252bbe;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 178.571% */
  letter-spacing: -0.5px;
}
.starsIcon {
  width: 60%;
}

.tq {
  color: #252bbe;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 178.571% */
  letter-spacing: -0.5px;
}
.rvw {
  width: 225px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 30px;
  background: var(--color-button);
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 106.667% */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  border: 2px solid var(--color-button);
}
.rvw:hover {
  background: #fff;
  color: #252bbe;
}
.rvw_title {
  color: #252bbe;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 178.571% */
  letter-spacing: -0.5px;
}

.rvw_title_2 {
  color: #252bbe;
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 131.579% */
  letter-spacing: -0.5px;
}
.rvw_input {
  border-bottom: 1px solid #dadada;
  padding: 1rem 0;
}
.input {
  border: none;
  width: calc(100% - 0.5rem);
  outline: none;
  padding: 0 0;
}
.input::placeholder {
  color: #979797;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.5px;
}

.file_btn {
  cursor: pointer;
  margin: 1rem 0;
  padding: 2rem;
  border-radius: 15px;
  border: 1px solid #979797;
}
.share_title {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 156.25% */
  letter-spacing: -0.5px;
  text-align: left;
}
.share_card {
  max-width: calc(519.953px - 6rem);

  border-radius: 10px;
  background: #252bbe;
  box-shadow: 0px 0px 25px 0px rgba(0, 25, 255, 0.15);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 3rem;
}
.share_card_title {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.share_link {
  /* width: auto; */
  /* max-width: calc(221.078px - 4rem); */
  padding: 0.5rem 0.5rem;
  border-radius: 5px;
  background: #fff;
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 106.667% */
  text-wrap: nowrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: horizontal;
}
.share_social_media {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: flex-start;
}
.share_social_media > img {
  cursor: pointer;
}
.share_frd {
  width: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 2.5rem;
}
.sign {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.sign > span {
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 100% */
}
.thanks_you {
  color: #252bbe;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 125% */
  letter-spacing: -0.5px;
}

@media screen and (max-width: 1000px) {
  .RP_main {
    width: calc(100% - 10%);
    padding: 2% 5%;
  }
}
@media screen and (max-width: 600px) {
  .RP_main {
    gap: 0.85rem;
    padding: 0% 5%;
  }
  .title {
    line-height: 25px;
  }
  .item {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
  }
  .share_frd {
    width: 100%;
  }
  .feedback {
    padding-left: 0;
  }
  .feed_item1 {
    height: 25px !important;
    width: 25px !important;
    object-fit: cover !important;
  }
}
