.MyItems {
  width: calc(100% - 6%);
  padding: 2% 3%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.MI_1st {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MI_1st_title {
  color: #3d40b5;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px; /* 116.667% */
}
.MI_1st_right {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}
.search {
  width: 111.004px;
  height: 34.348px;
  flex-shrink: 0;
  border-radius: 17.174px;
  background: var(--color-button);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
}
.recent {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 214.286% */
  text-transform: uppercase;
}
.ShowAll {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.cards {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
.card {
  width: 360px;
  display: flex;
  gap: 0.6rem;
  padding: 0.6rem;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.card_img > img {
  max-height: 150px;
  max-width: 150px;
  object-fit: contain;
}
.card_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  justify-content: flex-start;
}
.title {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px; /* 121.429% */
}
.img {
  width: 110.661px;
  height: 20.862px;
  flex-shrink: 0;
}
.price {
  color: #252bbe;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}
.price > span {
  color: #000;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
}

@media screen and (max-width: 800px) {
  .MI_1st_right {
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@media screen and (max-width: 500px) {
  .MI_1st {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
  }
  .cards {
    justify-content: center;
    align-items: center;
  }
  .card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .MI_1st_title {
    font-size: 23px;
  }
}
