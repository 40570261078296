.SAP {
  width: calc(100% - 6%);
  padding: 3%;
}

.SAP-items {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: flex-start;
}
.BB-img-div {
  width: 128.247px;
  height: 128.247px;
  flex-shrink: 0;
  fill: #fff;
  stroke-width: 2px;
  stroke: #5358d9;
  border-radius: 50%;
  border: 1px solid #5358d9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BB-item-img {
  width: 90px;
  height: 90px;
  object-fit: contain;
  flex-shrink: 0;
  border-radius: 32px;
}
.BB-item-img:hover {
  width: 98px;
  height: 98px;
}
.BB-item-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #4146bf;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 233.333% */
  width: 100%;
  max-width: 130px;
}
.BB-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  cursor: pointer;
}
.BB-Ele-title {
  color: #484eda;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 110% */
  cursor: pointer;
}
.BB-Ele-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.SAP-back {
  display: flex;
  gap: 1rem;
  font-family: Roboto;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 16px;
  color: #4146bf;
  font-weight: 400;
  margin: 1rem 0;
}
.SAPCat-items {
  width: calc(100% - 4%);
  padding: 1rem 2%;
}

@media screen and (max-width: 500px) {
  .SAP-items {
    gap: 1em;
  }
  .BB-item-desc {
    max-width: 130px;
    line-height: 20px;
    text-align: center;
  }
}
