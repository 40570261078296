.main_device_div_desc {
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 100% */
    text-transform: capitalize;
}

.img_title {
    color: #3F43A9;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    /* 85.714% */
}

.img_main {
    width: 126.381px;
    height: 78.988px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
}

.img_desc {
    border-radius: 50px;
    border: 1px solid #B3B5E8;
    background: #FFF;
    color: #343434;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    width: 204px;
    height: 38px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* 85.714% */
}