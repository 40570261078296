.input-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.input-title {
  margin-bottom: 5px;
  font-weight: 700;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  color: #3d40b5;
  line-height: 24px;
}
.input-wrap {
  position: relative;
  height: 35px;
  background-color: hsl(0, 0%, 100%);
  border-radius: 3px;
  min-width: 120px;
  cursor: text;
}
.input {
  outline: none;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 0.59rem 0 0.59rem 1rem !important;
  font-size: 14px;
  color: #000;
  border-radius: 5px;
  border: 1px solid #b3b5e8;
  background: #fff;
}
.pass-eye {
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  /* top: 0.30rem; */
  right: 0.55rem;
  cursor: pointer;
}
.checkbox {
  height: 15px;
  padding: 0;
  margin: 0;
  width: 15px;
  border: 1px solid #3d40b5;
  background-color: #fff;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox-main {
  height: 12px;
  width: 12px;
}

/* Slider CSS */

.slidecontainer {
  width: 100%;
  position: relative;
  z-index: 100;
  /* background-color: #000; */
  /* height: 100px; */
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background: #252bbe;
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  cursor: pointer;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 13px;
  height: 25px;
  border-radius: 30px;
  background: #252bbe;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #252bbe;
  cursor: pointer;
}

.step-lines {
  position: absolute;
  width: calc(100% - 1px);
  height: 5px;
  top: 90%;
  transform: translateY(-50%);
  z-index: -100;
}

.step-line {
  position: absolute;
  height: 100%;
  width: 2px;
  background: #252bbe;
  display: flex;
  z-index: 0;
}
.slider_values {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-top: 0rem;
  width: 300px;
  position: relative;
}
.slider_values > div {
  max-width: 80px;
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 106.667% */
  display: flex;
  justify-content: center;
}
.percent {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 160% */
}

/* MultiSlider CSS */

.multislider_container {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.multislider {
  position: relative;
  width: 350px;
}

.multislider__track,
.multislider__range,
.multislider__left-value,
.multislider__right-value {
  position: absolute;
}

.multislider__track,
.multislider__range {
  border-radius: 3px;
  height: 5px;
}

.multislider__track {
  background-color: #252bbe;
  width: 100%;
  z-index: 1;
}

.multislider__range {
  background-color: #252bbe;
  z-index: 2;
}

.multislider__left-value,
.multislider__right-value {
  color: #252bbe;
  font-size: 12px;
  margin-top: 20px;
}

.multislider__left-value {
  left: 6px;
}

.multislider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 350px;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #252bbe;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #252bbe;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #252bbe;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #252bbe;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.price_filter {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 106.667% */
}

@media screen and (max-width: 500px) {
  .input-div {
    width: 60vw !important;
  }
  .input-wrap {
    width: 60vw !important;
  }
  .input {
    width: 60vw !important;
  }
}
