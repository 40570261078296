.search-bar {
  width: 28vw;
  /* min-width: 300px; */
  height: 47px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #f0f2ff;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.search-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  cursor: pointer;
}
.search-focus {
  position: absolute;
  z-index: 11;
  background-color: #fff;
  width: 112%;
  min-height: 200px;
  border-radius: 10px;
  top: -10px;
  left: -10%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y:scroll ;
  max-height: 300px;
}
.search-focus::-webkit-scrollbar{
  display: none;
}
.SF-main-2 {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: calc(100% - 20%);
  padding: 0 10%;
}
.SF-main-2::-webkit-scrollbar {
  display: none;
}
.SF-main {
  /* min-height: 89px; */
  min-height: 50px;
}
.mike-icon {
  width: 12px;
  height: 18.857px;
  flex-shrink: 0;
  cursor: pointer;
}
.search-input {
  width: calc(100% - 0.5rem - 2rem - 36px);
  border: none;
  background: #f0f2ff;
  outline: none;
  padding: 0 0.5rem;
}
.search-input-blue::placeholder {
  color: #252bbe;
}
.SF-item {
  width: 100%;
  padding: 1rem 0;
  border-bottom: 1px solid #d1d3fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.SF-item-left {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}
.SF-item-left > img {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  cursor: pointer;
}
.SF-item-left > div {
  color: #252bbe;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.SF-item-right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.55rem;
}
.SF-item-right > div {
  padding: 0.35rem 0.55rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  color: white;
  background: #252bbe;
  border-radius: 50px;
  cursor: pointer;
  color: #fff;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SF-item-right > img {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  cursor: pointer;
}

@media screen and (max-width:1300px) {
  .SF-main-2 {
    width: calc(100% - 10%);    padding: 0 5%;
  }
}
@media screen and (max-width: 1000px) {
  .search-bar {
    transition: 0.2;
    width: 100%;
    height: 47px;
    flex-shrink: 0;
    border-radius: 50px;
    background: #f0f2ff;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
  }
  .search-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    cursor: pointer;
  }
  .mike-icon {
    width: 12px;
    height: 18.857px;
    flex-shrink: 0;
    cursor: pointer;
  }
  .search-input {
    width: calc(100% - 0.5rem - 2rem - 36px);
    border: none;
    background: #f0f2ff;
    outline: none;
    padding: 0 0.5rem;
  }
}

@media screen and (max-width: 500px) {
  .search-bar {
    width: 100%;
    height: 23px;
    padding: 0 0.25rem;
    justify-content: space-between;
  }
  .search-icon {
    padding-left: 0.15rem;
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    cursor: pointer;
  }
  .mike-icon {
    padding-right: 0.15rem;
    width: 8px;
    height: 12px;
    flex-shrink: 0;
    cursor: pointer;
  }
  .search-input {
    width: calc(100%);
    padding: 0 0rem;
    font-size: 9px;
  }
}
