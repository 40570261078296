/* Cat Menu Div */

.cat-menu-div {
  background-color: white;
  width: 250px;
  padding: 2rem;
  max-height: 80vh;
  position: absolute;
  top: 140%;
  border-radius: 5px;
  border: 1px solid #3035bb;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: scroll !important;
}
.cat-menu-div::-webkit-scrollbar {
  display: none;
}
.cat-menu-head {
  color: #263238;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 116.667%; /* 37.333px */
  padding-bottom: 1rem;
  border-bottom: 1px solid #c4c4c4;
  /* cursor: not-allowed; */
}
.cat-menu-items {
  display: flex;
  flex-direction: column;
  gap: 0.85rem;
}
.cat-menu-item {
  color: #aaa;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 116.667%; /* 28px */
}

.nav-manu {
  display: flex;
  gap: 1rem;
  color: #fff;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.nav-manu1 {
  display: flex;
}

.MU-input {
  width: 75% !important;
}

.nav {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100vw;
  /* height: 100%; */
}
.nav:hover {
  background-color: #fff;
}
.nav-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 5rem;
  width: calc(100% - 10rem);
  /* background-color: red; */
}

.nav-logo {
  width: 220px;
  height: 53px;
  flex-shrink: 0;
  object-fit: fill;
  color: rgba(0, 0, 0, 0.158);
}
.menu {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}
.item-1st-menu {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.head-icon {
  width: 31px;
  height: 27px;
  flex-shrink: 0;
}
.head-p {
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.menu-icon {
  display: none;
}
.modal {
  padding: 2rem;
  position: absolute;
  top: 140%;
  right: -250%;
  width: calc(501.862px - 4rem);
  /* height: calc(383.955px - 4rem); */
  border-radius: 5px;
  border: 1px solid #3035bb;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.modal-btn {
  border: none;
  cursor: pointer;
  width: 136.523px;
  height: 31.313px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #3d40b5;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.5s;
}
.modal-btn:hover {
  background-color: #fff;
  border: 1px solid var(--color-button);
  color: var(--color-button);
}
.modal2 {
  color: #2c2c2c;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}
.modal2 > span {
  cursor: pointer;
  font-weight: 500;
  text-decoration-line: underline;
}
.modal3 {
  border-top: 1px solid #b5b6f1;
  display: flex;
}
.modal3-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
  border-right: 1px solid #b5b6f1;
  padding: 1rem 0 0 0.5rem;
}
.m2-left-head {
  color: #2c2c2c;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  text-align: left;
  padding-bottom: 1rem;
}
.m2-left-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.m2-left-content > div {
  color: #2c2c2c;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-transform: capitalize;
  cursor: pointer;
}
.m2-left-content > div:hover {
  font-weight: bold;
}
.m3-left-1 {
  flex: 2;
}
.m3-left-2 {
  flex: 1;
}
.arrow-up {
  position: absolute;
  top: 96%;
  z-index: 10;
  color: white;
  font-size: 50px;
}
.menu-icon {
  display: none;
}

.signup {
  width: 50% !important;
}

.Queue-count {
  position: absolute;
  top: -15px;
  right: -9px;
  background-color: var(--color-notification);
  /* padding: 0.15rem 0.45rem !important; */
  border-radius: 50%;
  height: 25px;
  width: 25px;
  color: #252abe;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  border: 2.5px solid #252abe;
}

@media screen and (max-width: 1200px) {
  .menu {
    display: none;
  }
  .nav-main {
    justify-content: space-evenly;
    padding: 1rem 1rem;
    gap: 1rem;
    width: calc(100% - 2rem);
  }
  .logo {
    flex: 2;
    display: flex;
    align-items: center;
  }
  .nav-logo {
    width: 200px;
    height: 100%;
  }
  .search {
    flex: 3;
  }
  .menu-icon {
    position: relative;
    display: flex;
    align-items: center;
    flex: 2;
    width: 100%;
    color: #fff;
    font-size: 4rem;
    cursor: pointer;
    justify-content: flex-end;
    text-align: right;
  }
  .menu-item {
    z-index: 1000;
    position: absolute;
    top: 126%;
    right: -5%;
    width: 100vw;
    font-size: 10px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .menu-item1 {
    background-color: #fff;
    display: flex;
    width: calc(80%);
    color: #3035bb;

    justify-content: center;
    flex-direction: column;
    padding: 0.25rem 1rem;
  }
  .menu-item1 > div {
    display: flex;
    font-size: 20px;
    padding: 0.5rem 1rem;
  }
  .nav-manu1 {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .menu-item {
    right: -35%;
  }
  .menu-item1 > div {
    display: flex;
    font-size: 15px;
    padding: 0.5rem 1rem;
  }
  .nav-manu1 {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .nav-manu1 {
    display: none;
  }
  .menu {
    display: none;
  }
  .menu-icon {
    color: #fff;
    font-size: 30px;
  }
  .logo {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .search {
    flex: 4;
  }
  .menu-icon {
    height: 100%;
    display: flex;
    align-items: center;
    flex: 1;
  }
  .nav-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 1rem;
  }

  .nav-logo {
    width: 80px;
    height: 25px;
  }
  .MU-input {
    width: 100% !important;
  }
}
