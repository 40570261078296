.HC {
  display: flex;
  flex-direction: column;
  margin: 19rem 0rem 0rem 0rem;
  height: auto;
  gap: 5rem;
  width: 100%;
}
.hc-card-1 {
  position: relative;
}
.hc_card_1_div {
  display: flex;
  padding-left: 7rem;
}
.hc_card_2_div {
  display: flex;
  padding-right: 7rem;
}
.hc-card-div1 {
  padding: 19.5px 45px;
  position: absolute;
  left: -26.69px;
  bottom: 19.57px;
  width: calc(708px - 90px);
  height: calc(303px - 39.14px);
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  display: flex;
}
.hc-card-div2 {
  width: 708px;
  height: 303px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #dde1fe;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
.hc-item {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;
}

.hc-heading {
  color: #2c2c2c;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.hc-content {
  max-width: 80%;
  flex-shrink: 0;
  color: #2c2c2c;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}
.hc-btn-group {
  display: flex;
  justify-content: space-between;
  gap: 373.33px;
}

.hc-btn {
  width: 218px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50px;
  border: none;
  background: var(--color-button);
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  transition: 0.5s;
}
.hc-btn:hover {
  background: #fff;
  color: var(--color-button);
  border: 1px solid var(--color-button);
  transition: 0.5s;
}
.hc-btn2 {
  width: 218px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50px;
  border: none;
  background: #fff;
  color: #3d40b5;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  transition: 0.5s;
}
.hc-btn2:hover {
  background: var(--color-button);
  color: #fff;
  border: 1px solid #fff;
  transition: 0.5s;
}
.hc-images {
  position: relative;
}
.fly-img {
  position: absolute;
  top: -100px;
  left: auto;
  right: -220px;
}
.video-img2 {
  /* position: absolute; */
  /* right: 0px; */
  /* top: 190px; */
  width: 56px;
  height: 56px;
  flex-shrink: 0;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.5s ease;
}

.video-img2:hover {
  transform: scale(1.2);
  transition: transform 0.5s ease;
}
.video-img-text {
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  color: #fff;
  text-align: center;
}
.card-2nd {
  width: calc(100% - 3.4rem);
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;
}
.hc-card-2 {
  position: relative;
  margin-right: 1rem;
}
.hc-card2-div1 {
  padding: 19.5px 45px;
  position: absolute;
  right: -26.69px;
  bottom: 28.57px;
  width: calc(708px - 90px);
  height: calc(303px - 39.14px);
  flex-shrink: 0;
  border-radius: 10px;
  background: #5944d1;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
}
.hc-card2-div2 {
  width: 708px;
  height: 303px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #dde1fe;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
.hc-heading2 {
  color: white;
}
.whitebgColor {
  background-color: #fff;
}
.fly-img2 {
  position: absolute;
  top: -150px;
  right: -150px;
}
@media screen and (max-width: 1150px) {
  .HC {
    /* gap: 12rem; */
  }
}

@media screen and (max-width: 900px) {
  .HC {
    /* margin: 5rem 1rem; */
    /* gap: 8rem; */
  }
  .hc-card-1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hc-card-div1 {
    /* background-color: red; */
    padding: 0.5rem 0.5rem;
    height: calc(250px - 1rem);
    left: 10%;
    bottom: 20px;
    width: calc(75% - 1rem);
    flex-direction: column;
    gap: 1rem;
  }
  .hc-card-div2 {
    width: 75%;
    height: 250px;
  }
  .hc-tvicon {
    height: 30px;
    width: 30px;
    object-fit: fill;
  }
  .hc-tvicon2 {
    height: 45px;
    width: 45px;
    object-fit: fill;
  }
  .hc-item {
    gap: 1rem;
  }

  .hc-heading {
    font-size: 15px;
  }
  .hc-content {
    font-size: 12px;
    line-height: 16px;
  }

  .hc-btn {
    padding: 0.5rem 1rem;
    font-size: 13px;
  }
  .hc-btn2 {
    font-size: 13px;
    padding: 0.5rem 1rem;
  }
  .fly-img {
    top: -18rem;
    right: -75px;
    height: 200px;
    width: 200px;
    object-fit: fill;
  }
  .video-img2 {
    top: -50px;
    right: 15px;
    width: 40px;
    height: 40px;
  }

  .card-2nd {
    width: 100%;
  }
  .hc-card-2 {
    width: calc(100%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hc-card2-div1 {
    padding: 0.5rem 0.5rem;
    height: calc(250px - 1rem);
    right: 10%;
    bottom: 20px;
    width: calc(75% - 1rem);
    flex-direction: column;
    gap: 1rem;
  }

  .hc-card2-div2 {
    width: 75%;
    height: 250px;
  }
  .whitebgColor {
    background-color: #fff;
  }
  .fly-img2 {
    top: -325px;
    right: -70px;
    height: 200px;
    width: 200px;
    object-fit: fill;
  }
}
/* @media screen and (max-width: 700px) {
  .fly-img {
    display: none;
  }
  .fly-img2 {
    display: none;
  }

} */
@media screen and (max-width: 1010px) {
}

@media screen and (max-width: 500px) {
  .hc_card_1_div {
    display: flex;
    /* padding-left: 0.5rem; */
    background-color: #252bbe;
    /* width: 100%; */
    padding: 0;
  }
  .hc_card_2_div {
    display: flex;
    padding-right: 0.5rem;
  }
  .HC {
    gap: 1rem;
  }
  .hc-card-div1 {
    height: calc(320px - 1rem);
    left: 30px;
    bottom: 15px;
    width: calc(75% - 1rem);
    display: flex;
  }
  .hc-card-div2 {
    height: 320px;
  }

  .hc-btn {
    font-size: 11px;
  }
  .hc-btn2 {
    font-size: 11px;
  }
  .fly-img {
    top: -350px;
    right: -75px;
    height: 150px;
    width: 150px;
  }
  .video-img2 {
    top: -110px;
    right: -6px;
  }
  .hc-content {
    width: 500px;
  }

  .hc-card2-div1 {
    height: calc(340px - 1rem);
    left: 30px;
    bottom: 15px;
    width: calc(75% - 1rem);
  }

  .hc-card2-div2 {
    height: 340px;
  }
  .fly-img2 {
    top: -380px;
    right: -75px;
    height: 150px;
    width: 150px;
  }
}
@media screen and (min-width: 1500px) {
  .hc_card_1_div {
    display: flex;
    padding-left: 16rem;
    /* background-color: red; */
  }
  .hc_card_2_div {
    display: flex;
    padding-right: 16rem;
  }
}
