.PD-main {
  display: flex;
  flex-direction: column;
  width: calc(100% - 6%);
  padding: 2% 3%;
}
.textarea {
  border: none;
  outline: none;
  height: 80%;
}
.textarea::-webkit-scrollbar {
  display: none;
}
.PD-back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 100%;
  color: #252abe;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
  cursor: pointer;
}
.PD-details {
  display: flex;
  gap: 2rem;
  /* height: 500px; */
  /* overflow-y: scroll; */
}

.PD-details::-webkit-scrollbar {
  display: none;
}

.PD-img {
  flex: 2;
  display: flex;
  gap: 1rem;
  position: sticky;
  top: 0;
}
.PD-content {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.PD-sort-img {
  width: 151.592px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
  max-height: 420px;
  overflow-y: "scroll";
}
.PD-sort-img::-webkit-scrollbar {
  display: none;
}
.PD-sort-img > img {
  width: 120px;
  height: 100px;
}
.PD-sort-img > img:hover {
  border: 1px solid #252abe;
  width: calc(120px - 2px);
  height: calc(100px - 2px);
}
.PD-big-img {
  width: 600px;
  height: 400px;
  object-fit: contain;
}
.PD-big-img > img {
  width: 600px;
  height: 400px;
  object-fit: cover;
}
.PD-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
}
.PD-1st {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.PD-id {
  color: #252abe;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}
.PD-2nd {
  color: #252abe;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 600;
  word-wrap: break-word;
}
.PD-3rd {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.PD-rvw-quantity {
  color: #252bbe;
  font-size: 16px;
  font-family: Roboto Condensed;
  font-weight: 300;
  text-decoration: underline;
  text-transform: uppercase;
  line-height: 12px;
  word-wrap: break-word;
}
.PD-4th {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.Manu-agree {
  display: flex;
  gap: 1rem;
  align-items: center;
  border: 1px solid #252bbe;
  background-color: #f0f1ff;
  padding: 5px;
  border-radius: 5px;
}
.Manu-agree > div {
  color: #252bbe;
  font-size: 13px;
  font-family: Roboto Condensed;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 12px;
  word-wrap: break-word;
}
.PD-5th {
  width: 100%;
  display: flex;
  gap: 2rem;
}
.PD-5th > div {
  border: 1px solid #d9d9d9;
  padding: 1rem 2rem;
  border-radius: 5px;
}
.PD-5th > div:hover {
  border: 1px solid #252abe;
  color: #252abe;
  font-family: Roboto;
  font-weight: 600;
  word-wrap: break-word;
}
.PD-6th {
  width: calc(100% - 3rem);
  background-color: #f0f1ff;
  display: flex;
  padding: 1rem 1.5rem;
  border-radius: 10px;
  gap: 2rem;
}
.PD-6th-1 {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}
.PD-6th-1-title {
  color: #252bbe;
  font-size: 14px;
  font-family: Roboto Condensed;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 12px;
  letter-spacing: 1px;
  word-wrap: break-word;
}
.PD-6th-1-item {
  display: flex;
  gap: 1rem;
}
.item-rate {
  padding: 0 0.15rem;
  display: flex;
  align-items: center;
  color: white;
  font-size: 13px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 16px;
  word-wrap: break-word;
  border-radius: 2px;
  background: #252bbe;
}
.item-data {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5b64af;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.item-data > div {
  border: 1px solid #252bbe;
  border-radius: 50%;
  display: flex;
  width: 9.467px;
  height: 9.467px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 10.5px;
}
.PD-7th > div {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}
.PD-7th > ul {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.PD-compare {
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}
.PD-compare > div {
  background: #f2f4ff;
  padding: 0.5rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.PD-compare > div > p {
  color: #252bbe;
  text-align: center;
  font-family: Roboto Condensed;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  text-transform: uppercase;
}
.PD-compare > div > img {
  width: 80px;
  height: 25px;
}
.PD-or-get-it-form {
  margin-top: 1rem;
  color: #020a26;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
  opacity: 0.5;
}
.PD-feature {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.PD-feat-title {
  padding-bottom: 1rem;
  color: #252abe;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 2px solid #252abe;
}
.PD-feat-links {
  display: flex;
  gap: 2rem;
}
.PD-feat-links > div {
  color: #252bbe;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 0.5rem;
  cursor: pointer;
}
.PD-feat-links > div:hover {
  border-bottom: 2px solid #252bbe;
  padding-bottom: 0.5rem;
}
.PD-overview {
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}
.PD-over {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1.5rem 5rem 1.5rem 2rem;
  width: calc(50% - 7rem);
  display: flex;
  gap: 1rem;
  flex-direction: column;
  z-index: 1;
}
.PD-over::-webkit-scrollbar {
  display: none;
}
.PD-over-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid #dedede;
}
.PD-over-content-1 {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}
.PD-over-content-1 > div {
  height: 250px;
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #252bbe;
  overflow-y: scroll;
}
.PD-over-content-1 > div::-webkit-scrollbar {
  display: none;
}
.PD-over-content-2 {
  color: #8b8b8b;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PD-overview-img {
  /* position: absolute; */
  /* left: 46%; */
}
.PD-overview-img > img {
  z-index: 0;
  width: 600px;
  height: 400px;
  object-fit: fill;
  border-radius: 55px;
}

/* Experience Component */

.PD-experience-img {
  position: absolute;
  left: 45%;
  padding: 2rem 0.5rem 0 4rem;
  background-color: black;
  border-radius: 55px;
}
.PD-experience-img > img {
  z-index: 0;
  height: 400px;
  object-fit: cover;
  border-radius: 55px;
}

/* All Spaces */
.PD-allspecs {
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-direction: column;
}
.PD-allspecs-item {
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0.5rem 1rem;
}
.PD-allspecs-q {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PD-allspecs-q > div {
  color: #504949;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.PD-allspecs-ans {
  color: #504949;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Reviews Component */
.PD-rvw {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* width: 100%; */
}
.PD-rvw-title {
  color: #252abe;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 1rem;
  border-bottom: 2px solid #252abe;
}
.PD-rvw-main {
  /* width: calc(100% -2rem); */
  display: flex;
  gap: 2rem;
  align-self: center !important;
  justify-content: center !important;
  /* background-color: #5b64af; */
  width: 100%;
  align-self: center;
}
.PD-rvw-1st {
  height: fit-content;
  background-color: #fff; /* Use background-color instead of fill */
  box-shadow: 0px 0px 25px rgba(47, 87, 104, 0.15); /* Use box-shadow instead of filter */
  /* width: 34%; */
  padding: 1rem;
  /* flex: 1; */
}
.PD-rvw-2nd {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
}
.rvw-1st-1 {
  gap: 1rem;
  display: flex;
  width: 100%;
}
.rvw-item-title {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  margin-top: 1rem;
}
.RIT-1st {
  color: #020a26;
  font-family: Roboto Condensed;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  text-transform: uppercase;
}
.RIT-img {
  max-width: 100px;
}
.RIT-3rd {
  color: #252bbe;
  font-family: Roboto Condensed;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  letter-spacing: 1px;
  text-transform: uppercase;
}
.rvw-1st-2 {
  width: calc(100% - 10%);
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.rvw-1st-2 > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.rvw-1st-21 {
  color: #020a26;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  width: 20%;
  display: flex;
  justify-content: center;
}
.rvw-1st-22 {
  width: 55%;
}

.rvw-1st-21 {
  width: 20%;
  /* border-top: 1px solid #b7b9ee; */
}

.rvw-1st-3 {
  display: flex;
  width: calc(100% - 20%);
  margin: 2rem 10% 2rem 10%;
  gap: 1.5rem;
  border-top: 1px solid #b7b9ee;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rvw-1st-3-1 {
  margin-top: 1rem;
  color: #252bbe;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.rvw-1st-3-2 {
  color: #333;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.rvw-1st-3-3 {
  width: 225px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 30px;
  background: var(--color-button);
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  border: 2px solid var(--color-button);
}
.rvw-1st-3-3:hover {
  background: #fff;
  color: #252bbe;
}

/* Review 2nd */

.rvw-2nd-1st {
  color: #252bbe;
  text-align: center;
  font-family: Roboto Condensed;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.rvw-2nd-2nd {
  display: flex;
  width: 100%;
  gap: 0.85rem;
}
.rvw-card {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  padding: 0.85rem;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
.rvw-card-content {
  max-width: 290.632px;
  color: #252bbe;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
}
.rvw-card-btn {
  /* width: 82.827px;

  height: 14.725px; */
  width: calc(50% - 4rem);
  padding: 0.5rem 2rem;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 30px;
  background: var(--color-button);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
  border: 1px solid var(--color-button);
  transition: 0.5s;
}
.rvw-card-btn:hover {
  color: #252bbe;
  background-color: #fff;
}
.rvw-2nd-3rd {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.PD-filter2 {
  width: 100.359px;
  height: 37.782px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1.5px solid #252bbe;
  background: #f0f2ff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
.rvw-quantity {
  color: #252bbe;
  text-align: center;
  font-family: Roboto Condensed;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.rvw-comments {
  width: calc(100% - 3rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2rem 0 1rem;
}
.rvw-comment {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #b4b7f4;
}
.rvw-cmt-words {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.rvw-cmt-words-1 {
  color: #252bbe;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 178.571% */
  letter-spacing: -0.5px;
}
.rvw-cmt-words-2 {
  max-width: 442.951px;
  color: #252abe;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 178.571% */
  letter-spacing: -0.5px;
}
.rvw-cmt-words-4 {
  max-width: 442.951px;
  color: #252bbe;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 178.571% */
  letter-spacing: -0.5px;
  margin-bottom: 1rem;
}
.btn_payment {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  gap: 2rem;
}
@media screen and (max-width: 1250px) {
  .btn_payment {
    justify-content: flex-start;
  }
  .PD-details {
    display: flex;
    gap: 2rem;
    height: auto;
    overflow-y: scroll;
  }

  .PD-details::-webkit-scrollbar {
    display: none;
  }

  .PD-compare {
    justify-content: flex-start;
  }
  .PD-img {
    flex: 2;
    display: flex;
    gap: 1rem;
    position: static;
    top: 0;
  }
  .PD-or-get-it-form {
    width: 250px;
    margin-left: 50px;
  }
  .PD-sort-img {
    /* flex-direction: row; */
  }
  .PD-sort-img > img {
    width: 100px;
    height: 100px;
  }

  .PD-6th {
    width: fit-content;
  }
  .PD-sort-img > img:hover {
    border: 1px solid #252abe;
    width: calc(100px - 2px);
    height: calc(100px - 2px);
  }

  .PD-details {
    max-width: 800px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .PD-overview {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
  }
  .PD-overview-img {
    position: static;
    left: 0%;
  }
  .PD-overview-img > img {
    width: 100%;
  }
  .PD-over {
    width: 80%;
  }

  .PD-experience-img {
    position: static;
    left: 0%;
    padding: 0;
    background-color: black;
    border-radius: 35px;
  }
  .PD-experience-img > img {
    height: auto;
    width: 100%;
  }
  .PD-rvw-1st {
    width: fit-content;
    height: fit-content;
  }
  /* .PD-rvw-main {
    width: calc(100% - 2rem);
    display: flex;
    gap: 2rem;} */
  .rvw-2nd-2nd {
    flex-direction: column;
  }
  .rvw-card {
    width: fit-content;
  }
}

@media screen and (max-width: 800px) {
  .PD-details {
    justify-content: center;
  }
  .PD-img {
    flex-direction: column-reverse;
  }
  .PD-sort-img {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .PD-sort-img > img {
    /* width: 50px;
    height: 50px; */
    flex: 1;
  }
  .PD-big-img {
    width: 100%;
    height: auto;
  }
  .PD-big-img > img {
    width: 100%;
    height: auto;
  }
  .rvw-1st-1 {
    width: calc(100% - 2%);
    padding: 2% 2%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .PD-rvw-main {
    /* width: calc(100% - 2rem); */
    flex-direction: column;
    gap: 2rem;
  }
  .rvw-2nd-2nd {
    flex-direction: row;
  }
  .rvw-card-btn {
    width: auto;
  }
}

@media screen and (max-width: 500px) {
  .PD-feat-links {
    flex-direction: row;
    gap: 0.5rem;
  }
  .PD-feat-links > div {
    font-size: 15px;
  }
  .PD-sort-img > img {
    width: 50px;
    height: 50px;
  }
  .PD-sort-img > img:hover {
    width: calc(50px - 2px);
    height: calc(50px - 2px);
  }
  .PD-6th {
    flex-direction: row;
    width: calc(100% - 3rem);
  }
  .PD-5th {
    gap: 0.5rem;
  }
  .PD-5th > div {
    border: 1px solid #d9d9d9;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }
  .PD-rvw-quantity {
    line-height: 18px;
  }
  .PD-over {
    padding: 1rem 1rem 1rem 1rem;
    width: calc(100% - 2rem);
    display: flex;
    gap: 1rem;
    flex-direction: column;
    z-index: 1;
  }
  .rvw-2nd-2nd {
    width: max-content !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 410px) {
  .btn_payment {
    gap: 0.5rem;
  }
}
.np {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Pd-al-com {
  color: #504949;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-width: 45%;
}
