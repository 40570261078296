.QTS {
  margin: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: aqua; */
  width: 100%;
}
.QTS-heading {
  height: 46px;
  flex-shrink: 0;
  color: #2c2c2c;
  text-align: center;
  font-family: Roboto;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}
.QTS-qts {
  margin-top: 2rem;
  width: 60%;
  display: flex;
  flex-direction: column;
}
.qts-1 {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 2rem;
  gap: 5px;
}
.QTS-end {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  cursor: pointer;
}
/* .QTS-content {
  padding: 1rem 0;
  border-bottom: 10px solid #343ac3;
} */
.qts-answer { 
  color: #2c2c2c;
  font-weight: 400;
  padding: 0rem 1rem 1rem 2rem;
}
@media screen and (max-width: 500px) {
}
@media screen and (max-width: 800px) {
  .QTS {
    margin: 2rem 0;
  }
  .QTS-heading {
    width: 100%;
    font-size: 20px;
  }
  .QTS-qts {
    margin-top: 2rem;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .qts-1 {
    padding: 2rem 0;
  }
}

@media screen and (min-width: 1500px) {
  .QTS-qts {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
