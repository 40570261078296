.CI {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 4%);
  padding: 2rem 2%;
  height: auto;
}
.CI-top {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.CI-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  min-width: 308px;
  width: 25%;
}
.CI-left > div {
  color: #252abe;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.CI-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 3px solid #a3a5ea;
  padding-bottom: 2rem;
  gap: 1rem;
}

.CI-title-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CI-title {
  color: #252bbe;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.CI-expert {
  border-radius: 50px;
  background: linear-gradient(90deg, #252bbe 0%, #4146bf 50%, #7841e3 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 01rem;
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.CI-bottom {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.CI-bottom-left {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: calc(280px - 1rem - 2rem);
  margin: 0.5rem;
  height: auto;
  border-radius: 5px;
  border: 2px solid #252bbe;
  background: #dde1fe;
  padding: 5rem 1rem 2rem 1rem;
}
.CI-filter-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 5px solid #252bbe;
  padding: 0.5rem 0;
}
.CI-flt-title {
  color: #252abe;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.flt-clrall {
  color: #252abe;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.CI-flt {
  width: calc(100% - 2rem);
  border-radius: 5px;
  background: #fff;
  /* padding: 0.5rem 1rem; */
  flex-direction: column;
  display: flex;
  /* gap: 1rem; */
  width: 100%;
}
.CI-flt-main {
  display: flex;
  padding: 0.5rem 1rem 0.5rem 1rem;
  justify-content: space-between;
  /* background-color: red; */
}
.CI-flt-main > span {
  color: #252abe;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.flt-item-main {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.flt-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0.15rem 0; */
  padding: 0rem 1rem 0rem 1rem;
  gap: 0.1;
  color: #252bbe;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* background-color: #454545; */
}
.flt-items {
  padding-bottom: 0.5rem;
}

.CI-bottom-right {
  width: calc(100% - 4%);
  display: flex;
  flex-direction: column;
  /* width: calc(75% - 10%); */
  gap: 2rem;
  padding: 2% 2%;
}
.bt-right-sort {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bt-right-sort-left {
  width: 100%;
  display: flex;
  gap: 2rem;
  position: relative;
}
.bt-right-sort-left > div > div {
  display: flex;
  align-items: center;
  height: 43.564px;
  border-radius: 50px;
  border: 1.5px solid #252bbe;
  background: #f0f2ff;
  width: 200px;
  padding: 0 1.5rem;
  justify-content: space-between;
}
.bt-right-sort-left > div > div > p {
  cursor: pointer;
}
.bt-right-sort-left > span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.bt-right-sort-left > div > p {
  color: #252abe;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.bt-right-sort-right {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}

/* Card CSS Start */

.bt-right-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.CI-bt-card {
  padding: 1rem 1rem;
  /* width: calc(232px - 2rem); */
  width: calc(280px - 2rem);
  border-radius: 5px;
  background: #feffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid transparent;
}
.CI-bt-card:hover {
  /* width: calc(280px - 2rem - 2px); */
  border: 1px solid #252abe;
}

.card-1 {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}
.bt-card-title {
  color: #454545;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.property-card {
  border-radius: 5px;
  border: 1px solid #c2c5fa;
  background: #f2f4ff;
  width: calc(209px - 1rem);
  /* height: 126px; */
  flex-shrink: 0;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}
.PPcard-head {
  color: #484dc1;
  text-align: center;
  font-family: Roboto Condensed;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}
.PPCard-2 {
  color: #5b64af;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  padding: 0 0 0.5rem 0;
  border-bottom: 1px solid #9598ea;
}
.usage-Rating {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 7px;
}
.usage-Rating-1 {
  width: 47%;
  display: flex;
  gap: 0.5rem;
}
.usage-rat {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
  border-radius: 2px;
  background: #5157de;
  display: flex;
  padding: 0 0.1rem;
  justify-content: center;
  align-items: center;
}
.usage {
  color: #5b64af;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}
.or-get-it-form {
  color: #020a26;
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
  letter-spacing: 0.5px;
  text-transform: uppercase;
  opacity: 0.7;
}
.CI-compare {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}
.CI-compare > div {
  background: #f2f4ff;
  padding: 0.5rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.CI-compare > div > p {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  text-transform: uppercase;
}
.CI-quatity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(84.516px - 1rem);
  height: 27.044px;
  padding: 0 0.5rem;
  border-radius: 50px;
  background: #dde1fe;
}
.CI-quatity > div {
  color: #252bbe;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.CI-btn-1 {
  cursor: pointer;
  border-radius: 30px;
  border: 1px solid #252bbe;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(200.676px - 1.5rem);
  padding: 0.25rem 0.5rem 0.25rem 1rem;
}

.CI-btn-1-01 {
  font-family: Roboto Condensed;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  text-transform: uppercase;
}
.CI-btn-1-02 {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  display: flex;
  flex-direction: column;
}

.pagination {
  display: flex;
  width: 100%;
  padding: 1.5rem 0;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  border-top: 1px solid #7d81f0;
  border-bottom: 1px solid #7d81f0;
}
.page1 {
  flex: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.page1 > p {
  width: 33.228px;
  height: 34.113px;
  flex-shrink: 0;
  color: #252abe;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
/* .page1 > p:hover {
  border-radius: 5px;
  background: #252abe;
  color: white;
} */

.active_page {
  border-radius: 5px !important;
  background: #252abe !important;
  color: white !important;
}

.backtotop {
  flex: 2;
  cursor: pointer;
  color: #252abe;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: flex-end;
  text-decoration: dotted;
}

.fltLogo {
  position: absolute;
  top: -100px;
  right: 25%;
}
.item-img {
  width: 164.998px;
  height: 108.681px;
  object-fit: contain;
  cursor: pointer;
}

.CI-5th {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  /* cursor: pointer; */
  overflow-x: scroll;
  /* padding-inline: 20px; */
}
.CI-5th > div {
  border: 1px solid #d9d9d9;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 13px;
  /* margin-left: 10px; */
}
.CI-5th > div:hover {
  border: 1px solid #252abe;
  color: #252abe;
  font-family: Roboto;
  font-weight: 600;
  /* word-wrap: break-word; */
}
.CI-5th1 {
  border: 1px solid #d9d9d9;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
  /* margin-left: 10px; */
}
.CI-5th1:hover {
  border: 1px solid #252abe;
  color: #252abe;
  font-family: Roboto;
  font-weight: 600;
  /* word-wrap: break-word; */
}
.np1 {
  display: none;
}

@media screen and (max-width: 500px) {
  .bt-card-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .CI-left {
    width: 100%;
  }
  .np1 {
    display: block;
  }
  .np2 {
    display: none;
  }
  .CI-right {
    border: none;
  }
  .bt-right-cards {
    justify-content: center;
  }
  .CI-top {
    flex-direction: column;
  }
  .CI-bottom {
    margin-top: 5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bt-right-sort {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 1rem;
  }
  .DWM_2_sort {
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: center;
  }
  .CI-title {
    font-size: 18px;
  }
  .CI-expert {
    font-size: 16px;
  }
  .pagination > div {
    flex: 1;
  }
}
