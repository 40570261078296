.login {
  width: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .custom-textfield:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important; /* Override background color 
  background-color: #000 !important;
} */
.custom-textfield:-internal-autofill-selected {
  /* appearance: menulist-button; */
  /* background-image: none !important; */
  background-color: #fff !important;
  /* color: fieldtext !important; */
}
/* .custom-textfield::-inter {
  -webkit-text-fill-color: initial !important;
  -webkit-box-shadow: 0 0 0 1000px red inset !important;
  
} */

.log-main {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.25rem;
}
.log-tittle {
  color: #000;
  font-size: 17px;
  font-weight: 600;
  line-height: 25px;
}
.log-logo-img {
  width: 239px;
  height: 58px;
  flex-shrink: 0;
}
.log-form {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.log-FP {
  color: #252bbe;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
}
.log-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.lop-SM {
  display: flex;
  width: 100%;
  justify-content: center;
}
.keepin {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}
.keepin > div {
  height: 100%;
  height: auto;
}
.log-end {
  color: #252bbe;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
}

/* Sign Up Form */

.Sign-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.25rem;
}

.Sign-form {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.input-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}
.Sign-loc {
  color: #585858;
  text-align: center;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
.sign-input {
  width: 48% !important;
}

@media screen and (max-width: 1000px) {
  .sign-input {
    width: 45% !important;
  }
}

@media screen and (max-width: 500px) {
  .input-box {
    flex-direction: column;
  }
  .sign-input {
    width: 95% !important;
  }
}

.input_props {
  margin-right: 10px;
  background-color: red;
}
