.label_heading {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 41px;
  /* 205% */
  text-transform: capitalize;
}

.heading_button {
  width: 204px;
  height: 34px;
  border-radius: 17.174px;
  background: #5258d9;
  flex-shrink: 0;
  color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 41px;
  /* 315.385% */
  text-transform: capitalize;
}

.Customer_sub_heading {
  display: flex;
  flex-wrap: wrap;
  gap: 40%;
  align-items: center;
}

.data_to_loop {
  display: flex;
  border-radius: 5px;
  align-items: center;
  padding: 10px;
  width: 260px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.customer_sub_title {
  color: #000;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 41px;
  /* 273.333% */
}

.img_mainn {
  width: 62px;
  height: 62px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_titlee {
  color: #000;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  /* 130.769% */
}

.img_descc {
  color: #252bbe;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  /* 100% */
}

.buttons {
  display: flex;
  margin: 20px 0;
  justify-content: center;
  gap: 20px;
}

.buttons > button {
  border-radius: 17.174px;
  background: #5258d9;
  height: 34px;
  width: fit-content;
  padding: 0 10px;
  border: none;
  color: #fff;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* 315.385% */
  text-transform: capitalize;
}

.label_buttton > button {
  border-radius: 17.174px;
  background: #5258d9;
  height: 34px;
  width: fit-content;
  padding: 0 10px;
  border: none;
  color: #fff;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* 315.385% */
  text-transform: capitalize;
}

.label_description {
  color: #707172;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  /* 100% */
}

.thisss {
  border-radius: 5px;
  border: 1px solid #abaeb1;
}

.title_footer {
  color: #4347c9;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 41px;
  /* 273.333% */
}

.desc_footer {
  color: #545454;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  /* 216.667% */
}

.desc_footer > span {
  color: #545454;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.footer_section {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.title_footerr {
  color: #252abe;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 150% */
}

.desc_footerr {
  color: #252abe;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.foot_child {
  border-radius: 5px;
  border: 1px solid #bcbde7;
  background: #f0f2ff;
  padding: 10px;
  margin: 20px 0;
}

.footer_child {
  width: 353px;
  height: fit-content;
}
