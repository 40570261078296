.drawer_header {
  display: flex;
  padding: 1rem;
  align-items: center;
  gap: 1rem;
  border-bottom: 4px solid var(--color-primary);
  position: absolute;
  height: 55px;
  background-color: #fff;
  z-index: 1000;
  width: 100%;
}
.drawer_queue {
  height: 50px;
  width: 50px;
  object-fit: contain;
}
.drawer_count {
  height: 25px;
  width: 25px;
  background-color: var(--color-notification);
  border-radius: 50%;
  display: flex;
  position: absolute;
  top: -5px;
  right: -5px;
  justify-content: center;
  align-items: center;
  border: 1.5px solid var(--color-primary);

  color: var(--color-primary);
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
}
.drawer_img_div {
  position: relative;
}
.drawer_header_text {
  font-size: 25px;
  font-family: Roboto;
  font-weight: 600;
  color: var(--color-primary);
  flex: 1;
  /* width: 100%; */
}
.drawer_queue_div {
  padding: 1.5rem 2rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-top: 90px;
  position: relative;
  z-index: 0;
}
.drawer_queue_card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem 1.5rem;
  position: relative;
}
.drawer_queue_card_header_left {
  height: 124px;
  /* width: 180px; */
  display: flex;
  flex: 1;
}
.drawer_queue_card_img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
}
.drawer_queue_card_title {
  font-size: 13px;
  font-family: Roboto;
  font-weight: 300;
  color: var(--color-primary);
}
.drawer_queue_card_title1 {
  font-size: 15px;
  font-family: Roboto;
  font-weight: 600;
  color: var(--color-primary);
  margin-block: 0.5rem;
}
.drawer_queue_card_header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.drawer_queue_card_header_right {
  display: flex;
  flex-direction: column;
  flex: 1.3;
  position: relative;
}
.drawer_queue_card_trash {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #4b4b4b;
  cursor: pointer;
}
.drawer_queue_card_price_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.drawer_queue_card_price {
  font-size: 24px;
  font-family: Roboto;
  font-weight: 400;
  color: var(--color-text);
}
.drawer_queue_card_qty_div {
  background-color: var(--color-QTY);
  display: flex;
  border-radius: 50px;
  gap: 0.2rem;
  align-items: center;
  overflow: hidden;
}
.drawer_queue_card_qty_btn {
  padding: 5px 6px;
  display: flex;
  color: var(--color-primary);
  cursor: pointer;
}
.drawer_queue_card_qty_text {
  font-size: 14px;
  font-family: Roboto;
  font-weight: 600;
  color: var(--color-primary);
}
.drawer_queue_btn {
  background-color: var(--color-button);
  display: flex;
  height: 45px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 15px;
  font-family: Roboto;
  font-weight: 400;
  border-radius: 50px;
  padding: 0rem 1rem;
  flex: 1.5;
  cursor: pointer;
  transition: 0.5s;
  border: 1px solid var(--color-button);
}
.drawer_queue_btn:hover {
  background-color: #fff;
  border: 1px solid var(--color-button);
  color: var(--color-button);
}
.drawer_queue_card_footer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 15px;
}
.drawer_queue_card_footer_right {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.drawer_queue_card_footer_right_text1 {
  font-size: 12px;
  font-family: Roboto;
  font-weight: 700;
  color: var(--color-primary);
  text-transform: uppercase;
}
.drawer_queue_card_footer_right_text2 {
  font-size: 12px;
  font-family: Roboto;
  font-weight: 400;
  color: var(--color-primary);
}
@media screen and (max-width: 1000px) {
}
@media screen and (max-width: 500px) {
}
