.DC {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.DC-1st {
  width: 1010.82px;
  color: #2c2c2c;
  text-align: center;
  font-family: Roboto;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.DC-2nd {
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  margin-inline: 5rem;
}

.DC-3rd {
  width: calc(100% - 4rem);
  display: flex;
  /* flex-direction: column; */
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem;
}
.DC-3rd-1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.DC-3rd-head {
  width: 420.211px;
  color: #2c2c2c;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
}
.DC-3rd-content {
  margin-bottom: 1rem;
  width: 370.424px;
  color: #2c2c2c;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 150% */
}
@media screen and (max-width: 1010px) {
  .DC-1st {
    width: auto;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .DC-2nd {
  }
  .DC-3rd-img {
    height: 15rem;
    width: 20rem;
  }
}
@media screen and (max-width: 500px) {
  .DC {
    gap: 2rem;
    margin: 2rem 0;
  }
  .DC-1st {
    width: calc(100% - 2rem);
    padding: 0 1rem 0;
    font-size: 20px;
    text-align: left;
  }
  .DC-2nd {
    width: calc(100% - 2rem);
    padding: 0 1rem 0;
    font-size: 15px;
    line-height: 25px;
    text-align: left;
  }
  .DC-3rd {
    margin: 0.5rem 0;
    flex-direction: column;
    gap: 2rem;
    padding: 0;
    width: 100%;
  }
  .DC-3rd-reverse {
    flex-direction: column-reverse !important;
  }
  .DC-3rd-head {
    text-align: left;
    width: calc(100% - 2rem);
    padding: 0 1rem 0;
    font-size: 20px;
    line-height: 25px;
  }
  .DC-3rd-content {
    text-align: left;
    width: calc(100% - 2rem);
    padding: 0 1rem 0;
    font-size: 15px;
    line-height: 25px;
  }
  .DC-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .DC-3rd-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .DC-3rd-img {
    width: 80%;
    height: auto;
  }
}
