.main_browsingdata_component_heading {
    display: flex !important;
    gap: 12%;
}

.browsing_data_inner_div_child {
    border-radius: 5px;
    border: 2px solid #BDC0FD;
    background: #F0F2FF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
    margin: 20px 0;
}

.browsing_child_heading {
    width: 100%;
    display: flex;
    align-items: center;
}

.browsingdata_name {
    width: 60%;
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: flex-start;
}

.browsing_data_show_more {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.browsing_child_content {
    display: flex;
    width: 100%;

}

.browsing_url {
    width: 60%;
    display: flex;
    justify-content: flex-start;
    color: #5054B2;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px;
    margin:0;
    /* 273.333% */
}

.browsing_date {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    color: #757575;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px;
    margin:0;
    /* 273.333% */
}

.show_all {
    color: #5258D9;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 41px;
    /* 273.333% */
}

.heading_title {
    color: #5258D9;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 41px;
    /* 205% */
    text-transform: capitalize;
}

@media screen and (max-width:1200px) {
    .main_browsingdata_component_heading{
        display: block !important;
    }
}