.Range {
  display: flex;
  position: relative;
}
.np {
  height: 10px;
  background-color: #252BBE;
  border-radius: 10px !important;
  z-index: 1;
  border: initial;
}
.np2 {
  z-index: 0;
  height: 10px;
  border-radius: 10px !important;
  background-color: #DADADA;
}
