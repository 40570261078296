.category_module_main {
  display: flex;
  flex-direction: column;
}
.category_module_tab_div {
  display: flex;
  border-bottom: 1px solid #a3a7ec;
  padding: 0rem 2rem;
  height: 45px;
}
.category_module_tab {
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
  cursor: pointer;
  height: 45px;
}
.category_module_tab_text {
  font-size: 26px;
  flex: 1;
  font-family: Roboto;
  font-weight: 500;
  color: #4148c8;
  text-transform: capitalize;
}
.category_module_tab_line {
  height: 4px;
  background-color: #4148c8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
}
.category_module_tab_img {
  height: 35px;
  width: 35px;
  object-fit: contain;
}
.category_module_tab_content {
  background-color: #dde1fe;
  display: flex;
  padding: 0rem 10rem;
}
.sbc_main {
  display: flex;
  flex-direction: column;
  padding: 4rem 0rem;
}
.sbc_heading {
  font-size: 25px;
  font-family: Roboto;
  font-weight: 700;
  color: #4146bf;
  text-transform: capitalize;
}
.epp_heading {
  margin-top: 1rem;
}
.sbc_title {
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
  background-color: var(--color-button);
  padding: 6px 12px;
  border-radius: 20px;
  cursor: pointer;
  border: 1px solid var(--color-button);
  transition: 0.5s;
}
.sbc_title:hover {
  color: #5258d9;
  transition: 0.5s;
  background-color: #fff;
}
.sbc_category {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
}
.sbc_heading1 {
  font-size: 20px;
  font-family: Roboto;
  font-weight: 600;
  color: #484eda;
  text-transform: capitalize;
}
.sbc_show_all {
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  color: #252bbe;
  cursor: pointer;
}
/* .sbc_category_main_div {
  margin-top: 2rem; 
} */
.sbc_category_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  margin-top: 1rem;
}
.sbc_sub_category_div {
  display: flex;
  border-bottom: 1px solid #a3a5ea;
  gap: 1rem;
  padding: 1rem 0rem;
  /* overflow-y: scroll; */
  flex-wrap: wrap;

  /* background-color: red; */
  /* overflow-y: scroll;
  /* width: 100%; 
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-template-rows: auto auto auto auto auto auto auto auto auto;
  gap: 1rem;

  border-bottom: 1px solid #a3a5ea;
  padding-bottom: 1rem; */
}
.epp_card {
  display: flex;
  background-color: #5944d1;
  border-radius: 5px;
  padding: 2rem;
  flex-direction: column;
  position: relative;
}
.epp_card_heading_div {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.epp_card_exp_img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}
.epp_card_exp_img1 {
  height: 200px;
  width: 200px;
  object-fit: contain;
  position: absolute;
  top: -50px;
  right: -50px;
}
.epp_card_heading {
  font-size: 26px;
  font-family: Roboto;
  font-weight: 600;
  color: #fff;
}
.epp_card_text {
  font-size: 18px;
  font-family: Roboto;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.5px;
  margin-top: 1rem;
  line-height: 27px;
  margin-right: 100px;
}
.epp_card_play {
  height: 43px;
  width: 43px;
  object-fit: contain;
}
.epp_card_play_heading {
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: center;
}
.epp_card_play_div {
  display: flex;
  align-self: flex-end;
  gap: 10px;
  margin-top: 20px;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .category_module_tab_text {
    font-size: 20px;

    font-family: Roboto;
    font-weight: 500;
    color: #4148c8;
    text-transform: capitalize;
  }
  .category_module_tab_div {
    /* display: flex;
  border-bottom: 1px solid #a3a7ec;
  padding: 0rem 2rem; */
    height: 40px;
  }
  .category_module_tab {
    /* display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
  cursor: pointer; */
    height: 40px;
  }
  .category_module_tab_line {
    height: 3px;
  }
  .sbc_title {
    font-size: 15px;
  }
  .category_module_tab_img {
    height: 30px;
    width: 30px;
    object-fit: contain;
  }
  .category_module_tab_content {
    background-color: #dde1fe;
    display: flex;
    padding: 0rem 6rem;
  }
  /* .sbc_category_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    margin-top: 1rem;
  } */
}
@media screen and (max-width: 900px) {
  .category_module_tab_text {
    font-size: 18px;
    font-family: Roboto;
    font-weight: 500;
    color: #4148c8;
    text-transform: capitalize;
  }
  .category_module_tab_div {
    height: 30px;
  }
  .category_module_tab {
    height: 30px;
  }
  .category_module_tab_line {
    height: 2px;
  }
  .sbc_title {
    font-size: 14px;
  }
  .category_module_tab_img {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
  .category_module_tab_content {
    background-color: #dde1fe;
    display: flex;
    padding: 0rem 4rem;
  }
  .sbc_category_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    margin-top: 1rem;
  }
}
@media screen and (max-width: 500px) {
  .category_module_tab_text {
    font-size: 12px;

    font-family: Roboto;
    font-weight: 400;
    color: #4148c8;
    text-transform: capitalize;
  }
  .category_module_tab_div {
    height: 20px;
  }
  .category_module_tab {
    height: 20px;
  }
  .category_module_tab_line {
    height: 1px;
  }
  .sbc_title {
    font-size: 12px;
  }
  .category_module_tab_img {
    height: 15px;
    width: 15px;
    object-fit: contain;
  }
  .category_module_tab_content {
    background-color: #dde1fe;
    display: flex;
    padding: 0rem 2rem;
  }
  .sbc_category_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    margin-top: 1rem;
  }
}
