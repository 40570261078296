.expert_picker_inner_child {
    border-radius: 5px;
    border: 2px solid #BDC0FD;
    background: #F0F2FF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    width: 90%;
    padding: 15px;
}

.preferences_inner_div {
    background: white;
    width: 80vw;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    padding: 20px 2px;
}

.preferences_inner_sub_heading {
    color: #312FB7;
    font-family: Roboto;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 41px;
    margin: 0;
    /* 178.261% */
    text-transform: capitalize;
}

.inner_child_flexbox {
    display: flex;
    gap: 10%;
}

.inner_child_flexbox>ul>li {
    color: #252ABE;
    font-family: Roboto;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;

    /* 191.304% */
}

ul {
    list-style-type: none;
}

.checkbox_form {
    display: flex;
    gap: 15px;
    margin: 0;
    align-items: center;
}

.checkbox_form>p {
    margin: 0;
}

.preinner_child_flexbox {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
}

.preinner_child_flexbox>ul>li {
    margin: 10px 0;
    color: #252ABE;
    font-family: Roboto;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 51px;
    /* 221.739% */
}

.preinner_child_flexbox>ul>li>input[type='radio'] {
    height: 25px;
    font-size: 24px;
    width: 25px;
    border-radius: 50%;
    color: #5554C0;
}

.preinner_child_flexbox>ul>li>div>p{
    color: black;
}